import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const ChartComponent = ({ chartData, currentChart }) => {

    const [color, setColor] = React.useState('rgb(75, 192, 192)');

    React.useEffect(() => {
        if (currentChart === 'vk') {
            setColor('#0077ff');
        } else if (currentChart === 'spotify') {
            setColor('#ffffff');
        } else if (currentChart === 'yandex') {
            setColor('#ffbc0d');
        } else if (currentChart === 'ok') {
            setColor('#ffffff');
        } else if (currentChart === 'soundcloud') {
            setColor('#ffffff');
        } else if (currentChart === 'tiktok') {
            setColor('#ffffff');
        }
    }, [currentChart]);

    if (!chartData || Object.keys(chartData).length === 0) {
        var platform = '';
        if(currentChart === 'vk') {
            platform = '"Вконтакте"';
        } else if(currentChart === 'spotify') {
            platform = '"Spotify"';
        } else if(currentChart === 'yandex') {
            platform = '"Яндекс Музыка"';
        } else if(currentChart === 'ok') {
            platform = '"Одноклассники"';
        } else if(currentChart === 'soundcloud') {
            platform = '"SoundCloud"';
        } else if(currentChart === 'tiktok') {
            platform = '"TikTok"';
        }
        return <p>Информация по платформе {platform} отсутствует</p>;
    }

    const data = {
        labels: Object.keys(chartData),
        datasets: [
            {
                label: 'Количество прослушиваний',
                data: Object.values(chartData),
                fill: false,
                backgroundColor: color,
                borderColor: color,
            }
        ]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        maintainAspectRatio: false
    };

    return (
        <div style={{ height: "300px" }}>
            <Line data={data} options={options} />
        </div>
    );
};

export default ChartComponent;