var AlbumSess = (function() {
    var data = "";
  
    var getData = function() {
      return data;
    };

    var setData = function(props) {
      data = props;
    };
  
    return {
      getData: getData,
      setData: setData,
    }
  
  })();
  
  export default AlbumSess;