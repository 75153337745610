import React, { useState } from 'react';
import Cropper from "react-easy-crop";

const ImageCropper = ({ image, onCropDone, onCropCancel }) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const [croppedArea, setCroppedArea] = useState(null);
    const aspectRatio = 1 / 1;

    const onCropComplete = (cropperAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    }

    return (
        <div style={{height: "290px"}}>
            <div className="col-12 col-lg-4 col-md-6">
                <label className='btn p-absolute mainButton' onClick={onCropCancel}>
                    Отменить
                </label>

                <label className='btn mainButton ml-2' onClick={() => {
                    onCropDone(croppedArea);
                }}>
                    Сохранить
                </label>
            </div>
            <div className="col-12 col-lg-4 col-md-6 mt-3">
                <Cropper image={image} aspect={aspectRatio} crop={crop} zoom={zoom} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete}
                         style={{
                             containerStyle: {
                                 height: "200px",
                                 borderRadius: "10px",
                             },
                         }} />
            </div>
        </div>
    )
}

export default ImageCropper