import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const TrackContext = createContext();

export const Axios = axios.create({
    baseURL: 'https://api.muzilok.ru/',
});

function getToken() {
    const token = localStorage.getItem('_token');

    if (!token) {
        return sessionStorage.getItem('_token');
    }

    return token;
}

export const TrackContextProvider = ({ children }) => {

    const [theMyTracks, setMyTracks] = useState(null);
    const [theMyAlbums, setMyAlbums] = useState(null);
    const [wait, setWait] = useState(false);
    const [progress, setProgress] = useState(0);

    const createAlbum = async ({ name_track, version_track, artist_track, second_artist_track, zhanr_track, date_track, predate_track, type_track, lang_track, copyright_track, upc_track, image }) => {
        setWait(true);

        const _token = getToken();

        try {
            const { data } = await Axios.post('createAlbum', {
                _token,
                name_track,
                version_track,
                artist_track,
                second_artist_track,
                zhanr_track,
                date_track,
                predate_track,
                type_track,
                lang_track,
                copyright_track,
                upc_track,
                image
            });
            setWait(false);
            if(data.success === 1) {
                return { success: 1, id: data.id };
            }
            return { success: 0, message: data.message, type: data.type };
        }
        catch (err) {
            setWait(false);
            return { success: 0, message: 'Server Error!' };
        }
    }

    const editAlbum = async ({ albumid, name_track, version_track, artist_track, second_artist_track, zhanr_track, date_track, predate_track, type_track, lang_track, copyright_track, upc_track, image }) => {
        setWait(true);

        const _token = getToken();

        try {
            const { data } = await Axios.post('editAlbum', {
                _token,
                albumid,
                name_track,
                version_track,
                artist_track,
                second_artist_track,
                zhanr_track,
                date_track,
                predate_track,
                type_track,
                lang_track,
                copyright_track,
                upc_track,
                image
            }, {
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                }
            });
            setWait(false);
            console.log(data);
            if(data.success === 1) {
                return { success: 1, data: data };
            }
            return { success: 0, message: data.message, type: data.type };
        }
        catch (err) {
            setWait(false);
            return { success: 0, message: 'Server Error!' };
        }
    }

    const addTrack = async ({ album_id, titleTrack, versionTrack, artistTrack, secondArtistTrack, authorTrack, composerTrack, leksikaTrack, ISRCTrack, trackBase64data, tiktok_start }) => {
        setWait(true);

        const _token = getToken();

        try {
            const { data } = await Axios.post('addTrack', {
                _token,
                album_id,
                titleTrack,
                versionTrack,
                artist_track: artistTrack,
                second_artist_track: secondArtistTrack,
                authorTrack,
                composerTrack,
                leksikaTrack,
                ISRCTrack,
                trackBase64data,
                tiktok_start
            }, {
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                }
            });
            setWait(false);
            console.log(data);
            return data;
        }
        catch (err) {
            setWait(false);
            return { success: 0, message: 'Server Error!' };
        }
    }

    const editTrack = async ({ album_id, titleTrack, versionTrack, artistTrack, secondArtistTrack, authorTrack, composerTrack, leksikaTrack, ISRCTrack, trackBase64data, trackID, tiktok_start }) => {
        setWait(true);

        const _token = getToken();

        try {
            const { data } = await Axios.post('editTrack', {
                _token,
                album_id,
                titleTrack,
                versionTrack,
                artist_track: artistTrack,
                second_artist_track: secondArtistTrack,
                authorTrack,
                composerTrack,
                leksikaTrack,
                ISRCTrack,
                trackBase64data,
                trackID,
                tiktok_start
            }, {
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                }
            });
            setWait(false);
            console.log(data);
            return data;
        }
        catch (err) {
            setWait(false);
            return { success: 0, message: 'Server Error!' };
        }
    }

    const deleteTrack = async ({ trackid }) => {
        setWait(true);

        const _token = getToken();

        try {
            const { data } = await Axios.post('deleteTrack', {
                _token,
                trackid,
            }, {
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                }
            });
            setWait(false);
            console.log(data);
            return data;
        }
        catch (err) {
            setWait(false);
            return { success: 0, message: 'Server Error!' };
        }
    }

    const sendReliz = async (albumid) => {
        setWait(true);

        console.log(albumid);

        const _token = getToken();

        try {
            const { data } = await Axios.post('sendReliz', {
                _token,
                albumid,
            });
            setWait(false);
            console.log(data);
            return { success: data.success, message: data.message };
        }
        catch (err) {
            setWait(false);
            return { success: 0, message: 'Server Error!' };
        }
    }

    const getMyAlbums = async () => {
        const _token = getToken();

        if (_token) {
            const { data } = await Axios.post('getMyAlbumsWithToken', {
                _token: _token,
            });
            if (data.success) {
                setMyAlbums(data[0]);
                return data[0];
            }
            setMyAlbums(null);
        }
    }

    const getMyAlbum = async ({albumId}) => {
        const _token = getToken();

        if (_token) {
            const { data } = await Axios.post('getMyAlbumWithToken', {
                _token: _token,
                albumId: albumId,
            });
            if(data.success) {
                return data.album;
            } else {
                return data.message;
            }
        }
    }

    const getMyTrack = async ({trackID}) => {
        const _token = getToken();

        if (_token) {
            const { data } = await Axios.post('getTrack', {
                _token: _token,
                trackID: trackID,
            });
            if(data.success) {
                return data.track;
            } else {
                return data.message;
            }
        }
    }

    useEffect(() => {
        async function asyncCall() {
            await getMyAlbums();
            setMyTracks(null);
        }
        asyncCall();
    }, []);

    return (
        <TrackContext.Provider value={{ getMyAlbum, getMyAlbums, createAlbum, myTracks: theMyTracks, addTrack: addTrack, myAlbums: theMyAlbums, sendToModeration: sendReliz, editTrack, myAlbum: theMyAlbums, wait, deleteTrack, editAlbum, progress, getMyTrack }}>
            {children}
        </TrackContext.Provider>
    );

}

export default TrackContextProvider;