import React, { useRef } from 'react';

const FileInput = ({ onImageSelected, inputText, setError }) => {
    const inputRef = useRef();

    const handleOnChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const img = new Image();
            console.log(file);
            const url = URL.createObjectURL(Object(file));
            img.src = url;

            img.onload = () => {
                if (img.width > 2000 && img.height > 2000) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function(e) {
                        onImageSelected(reader.result);
                    };
                } else {
                    setError("Изображение должно быть размером 3000x3000 пикселей. Ваше изображение: " + img.width + "x" + img.height);
                }
                URL.revokeObjectURL(url);
            };

            img.onerror = () => {
                alert("Ошибка при загрузке изображения.");
            };
        }
    }

    const onChooseImg = () => {
        inputRef.current.click();
    };

    return (
        <div>
            <input type="file" accept="image/*" ref={inputRef} onChange={handleOnChange} style={{display: "none"}} />

            <div className="select-image-block" onClick={onChooseImg}>
                <div className="text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                         className="bi bi-image-fill" viewBox="0 0 16 16">
                        <path
                            d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
                    </svg>
                    <br />
                    <small className="text-muted">{inputText}</small>
                </div>
            </div>
        </div>
    )
}

export default FileInput;
