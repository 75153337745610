import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import axios from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Snackbar, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// Стилизация для react-select
const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: '#222222',
        color: '#ffffff',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#ffffff',
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#222222',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#444444' : '#222222',
        color: '#ffffff',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#ffffff',
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        color: '#ffffff',
    }),
};

// Кастомный компонент для меню с кнопкой "Создать артиста"
const MenuList = (props) => {
    return (
        <div>
            <components.MenuList {...props}>
                {props.children}
                {/* Кнопка "Создать артиста" */}
                <div style={{ padding: '10px', textAlign: 'center' }}>
                    <Button variant="contained" className={"btn mainButton"} startIcon={<AddIcon />} onClick={props.onAddArtist}>
                        Создать артиста
                    </Button>
                </div>
            </components.MenuList>
        </div>
    );
};

const ArtistSelect = ({ formData, setFormData, closeModal }) => {
    const [artists, setArtists] = useState([]);
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [selectedTypeArtist, setSelectedTypeArtist] = useState('main');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openAddArtistModal, setOpenAddArtistModal] = useState(false);
    const [newArtist, setNewArtist] = useState({ username: '', vk_link: '', spotify_link: '' });

    function getToken() {
        const token = localStorage.getItem('_token');

        if (!token) {
            return sessionStorage.getItem('_token');
        }
        return token;
    }

    const Token = getToken();

    useEffect(() => {
        axios.get('https://api.muzilok.ru/getArtists?_token=' + Token)
            .then(response => {
                if (response.data !== false) {
                    setArtists(response.data.map(artist => ({
                        value: artist.username,
                        label: artist.username,
                        vk_link: artist.vk_link,
                        spotify_link: artist.spotify_link,
                        adder: artist.adder
                    })));
                }
            })
            .catch(error => {
                console.error("There was an error fetching the artists!", error);
            });
    }, [Token]);

    const addArtist = () => {
        if (!selectedArtist) {
            alert('Выберите артиста.');
            return;
        }

        const artistName = selectedArtist.value;

        const checkDuplicate = (artistArray, artistName) => {
            return Array.isArray(artistArray) && artistArray.includes(artistName);
        };

        const showErrorSnackbar = (message) => {
            setSnackbarMessage(message);
            setSnackbarOpen(true);
        };

        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData };

            if (checkDuplicate(updatedFormData.artistTrack, artistName) || checkDuplicate(updatedFormData.artist_track, artistName) ||
                checkDuplicate(updatedFormData.secondArtistTrack, artistName) || checkDuplicate(updatedFormData.second_artist_track, artistName)) {
                showErrorSnackbar('Артист уже добавлен.');
                return prevFormData;
            }

            if (selectedTypeArtist === 'main') {
                if (Array.isArray(updatedFormData.artistTrack)) {
                    updatedFormData.artistTrack = [...updatedFormData.artistTrack, artistName];
                } else if (Array.isArray(updatedFormData.artist_track)) {
                    updatedFormData.artist_track = [...updatedFormData.artist_track, artistName];
                } else {
                    updatedFormData.artistTrack = [artistName];
                }
            } else {
                if (Array.isArray(updatedFormData.secondArtistTrack)) {
                    updatedFormData.secondArtistTrack = [...updatedFormData.secondArtistTrack, artistName];
                } else if (Array.isArray(updatedFormData.second_artist_track)) {
                    updatedFormData.second_artist_track = [...updatedFormData.second_artist_track, artistName];
                } else {
                    updatedFormData.secondArtistTrack = [artistName];
                }
            }

            return updatedFormData;
        });

        setSelectedArtist(null);
        closeModal();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage('');
    };

    const handleAddArtistClick = () => {
        setOpenAddArtistModal(true);
    };

    const handleCloseAddArtistModal = () => {
        setOpenAddArtistModal(false);
        setNewArtist({ username: '', vk_link: '', spotify_link: '' });
    };

    const handleCreateArtist = async () => {
        try {
            let newArtistAxios = await axios.get(`https://api.muzilok.ru/addArtist?_token=${Token}&vk_link=${newArtist.vk_link}&spotify_link=${newArtist.spotify_link}&username=${newArtist.username}`);
            newArtistAxios = newArtistAxios.data;
            console.log(newArtistAxios)
            if(newArtistAxios.status === undefined) {
                alert(newArtistAxios.message);
            } else {
                setOpenAddArtistModal(false);
                let artists_temp = [
                    ...artists,
                    {
                        value: newArtistAxios.artist.username,
                        label: newArtistAxios.artist.username,
                        vk_link: newArtistAxios.artist.vk_link,
                        spotify_link: newArtistAxios.artist.spotify_link,
                        adder: newArtistAxios.artist.adder
                    }
                ];

                console.log(artists_temp);
                setArtists(artists_temp)

                setNewArtist({ username: '', vk_link: '', spotify_link: '' });
            }

        } catch (error) {
            console.error('Error adding artist:', error);
        }
    };

    return (
        <div>
            <Select
                value={selectedArtist}
                onChange={setSelectedArtist}
                options={artists}
                styles={customStyles}
                placeholder="Выберите артиста"
                className="mb-4"
                filterOption={(option, inputValue) =>
                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                noOptionsMessage={() => "Артистов не найдено"}
                components={{MenuList: (props) => <MenuList {...props} onAddArtist={handleAddArtistClick}/>}}
            />

            {/* Модальное окно для создания нового артиста */}
            <Dialog open={openAddArtistModal} onClose={handleCloseAddArtistModal}>
                <DialogTitle>Создать нового артиста</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Введите информацию об артисте.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Имя артиста"
                        type="text"
                        fullWidth
                        value={newArtist.username}
                        onChange={(e) => setNewArtist({...newArtist, username: e.target.value})}
                    />
                    <TextField
                        margin="dense"
                        label="Ссылка на VK"
                        type="text"
                        fullWidth
                        value={newArtist.vk_link}
                        onChange={(e) => setNewArtist({...newArtist, vk_link: e.target.value})}
                    />
                    <TextField
                        margin="dense"
                        label="Ссылка на Spotify"
                        type="text"
                        fullWidth
                        value={newArtist.spotify_link}
                        onChange={(e) => setNewArtist({...newArtist, spotify_link: e.target.value})}
                        className={"text-white"}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddArtistModal} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleCreateArtist} color="primary" className={"btn mainButton"}>
                        Создать
                    </Button>
                </DialogActions>
            </Dialog>

            <div className="select-type-artist-block row">
                <div className="select-type-artist col-12 col-lg-6">
                    <Button
                        className={selectedTypeArtist === 'main' ? 'select-type-artist-content active-select-type-artist text-left' : 'select-type-artist-content text-left'}
                        onClick={() => setSelectedTypeArtist('main')}>
                        <b>Основной артист</b> <br/>
                        <small className="text-muted">Главный артист в релизе</small>
                    </Button>
                </div>
                <div className="select-type-artist col-12 col-lg-6">
                    <Button
                        className={selectedTypeArtist === 'feat' ? 'select-type-artist-content active-select-type-artist text-left' : 'select-type-artist-content text-left'}
                        onClick={() => setSelectedTypeArtist('feat')}>
                        <b>feat.</b> <br/>
                        <small className="text-muted">Дополнительный артист</small>
                    </Button>
                </div>
            </div>

            <div className="w-100 d-flex justify-content-end">
                <label className="btn border border-danger" style={{width: "min-content"}}
                       onClick={closeModal}>Отмена</label>
                <label className="btn mainButton ml-2" onClick={addArtist}>Добавить</label>
            </div>

            {/* Snackbar для отображения ошибок */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert onClose={handleSnackbarClose} severity="error" sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ArtistSelect;

