import React, { useContext, useState, useEffect } from 'react';
import classes from './SearchInput.module.css';
import { UserContext } from './../../API/UserContext';
import { TrackContext } from './../../API/TrackContext';
import { Link } from 'react-router-dom';

const SearchInput = () => {
    const { user } = useContext(UserContext);
    const { getMyAlbums } = useContext(TrackContext);
    const [myAlbums, setMyAlbums] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
        }, 100); // Задержка 100ms для возможности нажать на ссылку
    };

    useEffect(() => {
        loadAlbums();
    }, []);

    const loadAlbums = async () => {
        const albums = await getMyAlbums();
        setMyAlbums(albums);
    };

    const filteredAlbums = myAlbums !== undefined ? myAlbums.filter(album =>
        album.name.toLowerCase().includes(searchText.toLowerCase()) ||
        album.upc.toLowerCase().includes(searchText.toLowerCase()) ||
        album.copyright.toLowerCase().includes(searchText.toLowerCase())
    ) : {};

    return (
        <>
            {user && (
                <>
                    <input
                        type="text"
                        placeholder='Поиск'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className={classes.mySearchInput}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    <div className={`searchPopup p-2 ${isFocused ? 'd-block' : 'd-none'}`}>
                        {filteredAlbums !== undefined && filteredAlbums.length > 0 ? filteredAlbums.map((album, index) => (
                            <div className="card-body rounded mt-2" key={index}>
                                <div className="searchResultItem row">
                                    <div className="col-4 col-lg-2 pr-3">
                                        <img src={album.img_name} className="rounded" width="100%" alt={album.name} />
                                    </div>
                                    <div className="col-8 col-lg-10">
                                        <Link to={`/album/${album.code}`} onMouseDown={(e) => e.preventDefault()}>
                                            <h4>{album.name}</h4>
                                        </Link>
                                        <p>UPC: {album.upc}</p>
                                        <p>Copyright: {album.copyright}</p>
                                    </div>
                                </div>
                            </div>
                        )) : null}
                        {
                            filteredAlbums.length < 1 && (
                                <p className={"text-center mt-4"}>Результатов по запросу "{searchText}" не найдено</p>
                            )
                        }
                    </div>
                </>
            )}
        </>
    );
};

export default SearchInput;