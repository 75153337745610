import React, { createContext, useContext, useState } from 'react';

const LargePlayerContext = createContext();

export const usePlayer = () => useContext(LargePlayerContext);

export const LargePlayerContextProvider = ({ children }) => {
    const [trackUrl, setTrackUrl] = useState(null);
    const [artist, setArtist] = useState(null);
    const [title, setTitle] = useState(null);
    const [imgName, setImgName] = useState(null);
    const [trackList, setTrackList] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false)
    const [albumId, setAlbumId] = useState(null);

    const playTrack = (url, title, artist, img_name, ) => {
        setTrackUrl(url);
        setIsPlaying(true);
        setArtist(artist);
        setTitle(title);
        if(img_name !== undefined) {
            setImgName(img_name);
        }
    };

    const togglePlay = (title, artist, img_name, ) => {
        setIsPlaying(!isPlaying);
        setArtist(artist);
        setTitle(title);
        if(img_name !== undefined) {
            setImgName(img_name);
        }
    };

    return (
        <LargePlayerContext.Provider value={{ trackUrl, setTrackUrl, isPlaying, playTrack, togglePlay, artist, title, imgName, trackList, setTrackList, setAlbumId, albumId, albumPlayerID: albumId, }}>
            {children}
        </LargePlayerContext.Provider>
    );
};
