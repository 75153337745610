import {createContext, useState, useEffect} from 'react'
import axios from 'axios'

export const UserContext = createContext();

export const Axios = axios.create({
    baseURL: 'https://api.muzilok.ru/',
});

function getToken() {
    const token = localStorage.getItem('_token');

    if (!token) {
        return sessionStorage.getItem('_token');
    }
    return token;
}

export const UserContextProvider = ({children}) => {

    const [theUser, setUser] = useState(null);
    const [wait, setWait] = useState(false);
    const [tokenB, setTokenB] = useState('');

    const registerUser = async ({username,email,password, passwordRepeat}) => {
        setWait(true);
        try{
            const { data } = await Axios.post('register',{
                username,
                email,
                password,
                passwordRepeat
            });
            console.log(data);
            setWait(false);
            if(data._token){
                localStorage.setItem('_token', data._token);
                setWait(false);
                return {success:1, _token: data._token};
            }
            return data;
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Ошибка сервера!'};
        }
    }

    const editPersonalInfoUser = async ({name, lastname, surname}) => {

        const _token = getToken();

        setWait(true);
        try{
            const {data} = await Axios.post('editPersonalInfo',{
                _token,
                name,
                lastname,
                surname,
            });
            setWait(false);

            loggedInCheck();

            setUser(theUser);

            return data;
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Ошибка сервера!'};
        }
    }
    const changeLinksUser = async({ vkLink, spotify }) => {
        const _token = getToken();

        setWait(true);

        try{
            const {data} = await Axios.post('changeLinks',{
                _token,
                vkLink,
                spotify
            });

            setWait(false);

            loggedInCheck();

            setUser(theUser);

            return data;
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Ошибка сервера!'};
        }
    }

    const loginUser = async ({username,password,rememberMe}) => {

        const TelegramWindow = window.Telegram.WebApp;
        if (typeof(TelegramWindow.initDataUnsafe.user) == 'undefined') {
            var webapp = false;
            var telegramData = {};
        } else {
            var webapp = true;
            var telegramData = {
                userid: `${TelegramWindow.initDataUnsafe.user.id}`,
                first_name: `${TelegramWindow.initDataUnsafe.user.first_name}`,
                last_name: `${TelegramWindow.initDataUnsafe.user.last_name}`,
                username: `${TelegramWindow.initDataUnsafe.user.username}`
            };
        }

        setWait(true);

        try{
            const {data} = await Axios.post('login',{
                username,
                password,
                webapp: webapp,
                telegramData: telegramData,
                test: 'test'
            });
            if(data._token){
                if(rememberMe) {
                    localStorage.setItem('_token', data._token);
                } else {
                    sessionStorage.setItem('_token', data._token);
                }
                setWait(false);
                return {success:1, _token: data._token};
            }
            setWait(false);
            return {success:0, message: data.message};
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Ошибка сервера!'};
        }
    }

    const loginUserTelegram = async () => {

        const TelegramWindow = window.Telegram.WebApp;
        if (typeof(TelegramWindow.initDataUnsafe.user) == 'undefined') {
            var webapp = false;
            var telegramData = {};

            return;

        } else {
            var webapp = true;
            var telegramData = {
                userid: `${TelegramWindow.initDataUnsafe.user.id}`,
                first_name: `${TelegramWindow.initDataUnsafe.user.first_name}`,
                last_name: `${TelegramWindow.initDataUnsafe.user.last_name}`,
                username: `${TelegramWindow.initDataUnsafe.user.username}`
            };
        }

        setWait(true);

        try{
            const {data} = await Axios.post('loginTelegram',{
                userid: telegramData.userid
            });

            if(data._token){
                sessionStorage.setItem('_token', data._token);
                setWait(false);
                return {success:1, _token: data._token};
            }

            setWait(false);
            return {success:0, message: data.message};

        }
        catch(err){
            setWait(false);
            return {success:0, message:'Ошибка сервера!'};
        }
    }

    const loggedInCheck = async () => {
        const _token = getToken();
        
        if(_token){
            const {data} = await Axios.post('LoginInWithToken', {
                _token: _token,
            });
            if(data.id){
                setUser(data);
                return;
            }
            setUser(null);
        }
    }

    useEffect(() => {
        async function asyncCall(){
            await loggedInCheck();
        }
        asyncCall();
    },[]);

    const logout = () => {
        localStorage.removeItem('_token');
        sessionStorage.removeItem('_token');
        setUser(null);
    }

    return (
        <UserContext.Provider value={{registerUser, loginUserTelegram, changeLinksUser, editPersonalInfoUser, loginUser, wait, user:theUser, loggedInCheck, logout}}>
            {children}
        </UserContext.Provider>
    );

}

export default UserContextProvider;