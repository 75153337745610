import React, { useState, useEffect, useRef, useContext } from 'react';
import logo from './../../assets/images/logo.png';
import { UserContext } from '../../API/UserContext';
import { TrackContext } from '../../API/TrackContext';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function getToken() {
    const token = localStorage.getItem('_token');

    if (!token) {
        return sessionStorage.getItem('_token');
    }
    return token;
}

function ChatComponent({ album }) {
    const [ws, setWs] = useState(null);
    const [messages, setMessages] = useState([]);
    const [isScrolledUp, setIsScrolledUp] = useState(false);
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const albumId = album?.id ? album.id : 0;

    const [errorMessage, setErrorMessage] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState('');

    const [openErrorMessage, setOpenErrorMessage] = React.useState(false);
    const [openSuccessMessage, setOpenSuccessMessage] = React.useState(false);

    const { vertical, horizontal } = { vertical: 'top', horizontal: 'center' };

    const [moderationsList, setModerationList] = React.useState([]);

    const { user } = useContext(UserContext);
    const { sendToModeration } = useContext(TrackContext);

    const getModerationsList = async (album) => {
        const _token = getToken();

        const params = {
            id: album.id,
            _token: _token
        };

        const response = await fetch('https://api.muzilok.ru/getModerations', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        });

        const data = await response.json();
        return data;
    };

    useEffect(() => {
        const fetchModerationsList = async () => {
            const response = await getModerationsList(album);
            setModerationList(response);
        };

        fetchModerationsList();
    }, [album]);

    const paramsStatuses = {
        0: "Альбом отправлен на модерацию",
        1: "Альбом не прошел проверку",
        2: "Альбом успешно опубликован",
        3: "Альбом на удалении",
        4: "Альбом удален"
    }

    return (
        <div className="col-12">
            <Snackbar open={openSuccessMessage} anchorOrigin={{vertical, horizontal}} autoHideDuration={6000}
                      onClose={() => setOpenSuccessMessage(false)}>
                <div className="alert alert-success d-flex align-items-center"
                     onClose={() => setOpenSuccessMessage(false)}
                     severity="success"
                     variant="filled"
                     sx={{width: '100%'}}
                >
                    {successMessage}
                    <svg onClick={() => setOpenSuccessMessage(false)} xmlns="http://www.w3.org/2000/svg" width="35"
                         height="35" fill="#155724"
                         className="bi bi-x" viewBox="0 0 16 16">
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                    </svg>
                </div>
            </Snackbar>
            <Snackbar open={openErrorMessage} anchorOrigin={{vertical, horizontal}} autoHideDuration={6000}
                      onClose={() => setOpenErrorMessage(false)}>
                <div className="alert alert-danger d-flex align-items-center"
                     onClose={() => setOpenErrorMessage(false)}
                     severity="danger"
                     variant="filled"
                     sx={{width: '100%'}}
                >
                    {errorMessage}
                    <svg onClick={() => setOpenErrorMessage(false)} xmlns="http://www.w3.org/2000/svg" width="35"
                         height="35" fill="#155724"
                         className="bi bi-x" viewBox="0 0 16 16">
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                    </svg>
                </div>
            </Snackbar>
            <div className="mt-2 w-100 pb-2 d-flex align-items-center row justify-content-between">
                <div className="d-flex align-items-center col-12 col-md-6">
                    <img src={logo} alt="logo" width={80}/>
                    <h3>Модерация альбома</h3>
                </div>
            </div>
            <div className="col-12 col-md-12">
                {
                    album?.status_added === 0 ? (
                        <>
                            <button
                                className="btn mainButton p-2 mt-2 d-flex align-items-center"
                                onClick={() => sendToModeration(album?.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                     height="16"
                                     fill="currentColor"
                                     className="bi bi-send mr-2" viewBox="0 0 16 16">
                                    <path
                                        d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
                                </svg>
                                Отправить на модерацию
                            </button>
                            {album?.admin_comment && album?.status_added < 1 && (
                                <p className="mt-2 border rounded p-3 border-danger"><b>Комментарий
                                    админа:</b> <br/>{album?.admin_comment}</p>
                            )
                            }
                        </>

                    ) : (
                        <>
                            {
                                album?.platform_status === 1 && album?.status_added === 1 && (
                                    <p className="mt-2 border rounded p-2 border-success">
                                        Релиз проверен на <a
                                        href="https://muzilok.ru">MUZILOK</a> и
                                        отправлен проверяться на площадки
                                    </p>
                                )
                            }
                            {album?.status_added == 2 && (
                                <p className="mt-2 border rounded p-3 border-success"><b>Альбом успешно опубликован</b>
                                </p>
                            )
                            }
                        </>
                    )
                }
            </div>

            {
                Object.values(moderationsList).map(mod => (
                    <div className={"card mt-2"} key={mod.id}>
                        <div className={`card-body rounded border border-${mod.status == 0 ? 'secondary' : mod.status == 1 ? 'danger' : 'success'}`}>
                            <p><b>Статус:</b> {paramsStatuses[mod.status]}</p>
                            {
                                mod.status == 1 && (
                                    <p><b>Комментарий администратора:</b> {mod.admin_comment}</p>
                                )
                            }
                            {
                                mod.status == 2 && (
                                    <p>Альбом успешно выложен на площадки! На странице "Альбом" вы можете сгенерировать смарт-ссылку для рекламы альбома.</p>
                                )
                            }
                            <small>
                                <p>Заявка отправлена: <i>{mod.created_at}</i></p>
                            </small>
                            {
                            mod.created_at !== mod.updated_at && (
                                    <small><p>Ответ получен: <i>{mod.updated_at}</i></p></small>
                                )
                            }
                        </div>
                    </div>
                ))
            }

        </div>
    );
}

export default ChatComponent;