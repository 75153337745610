import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TrackContext } from '../API/TrackContext';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button, Typography, Tab, Tabs, Card, CardMedia, CardContent, Grid, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';

const CustomCard = styled(Card)(({ theme }) => ({
    backgroundColor: '#212121',
    color: '#fff',
    borderRadius: '15px',
}));

const MyAlbumsList = () => {
    const { getMyAlbums } = useContext(TrackContext);
    const [loading, setLoading] = useState(true);
    const [myAlbums, setMyAlbums] = useState([]);
    const [activeTab, setActiveTab] = useState('all');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        loadAlbums();
    }, []);

    const loadAlbums = async () => {
        const albums = await getMyAlbums();
        setMyAlbums(albums);
        setLoading(false);
    };

    useEffect(() => {
        var title = 'Мои релизы | MUZILOK';
        document.title = title;
    }, []);

    const statusDescriptions = {
        0: 'Черновик',
        1: 'На модерации',
        2: 'Опубликован',
        3: 'На удалении',
        4: 'Удален'
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const filteredAlbums = myAlbums.filter(album => activeTab === 'all' || album.status_added.toString() === activeTab);

    const animationVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    };

    const [openImage, setOpenImage] = useState(false);
    const [imageLink, setImageLink] = useState('');

    const handleCloseImage = () => {
        setOpenImage(false);
        setImageLink('');
    }

    return (
        <>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <h3>Мои релизы</h3>
                    <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            aria-label="album status tabs"
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                        >
                            <Tab label="Все" value="all" />
                            <Tab label="Черновики" value="0" />
                            <Tab label="На модерации" value="1" />
                            <Tab label="Опубликован" value="2" />
                            <Tab label="На удалении" value="3" />
                            <Tab label="Удален" value="4" />
                        </Tabs>
                    </Box>
                    {filteredAlbums.length ? (
                        <Grid sx={{padding: 0, marginTop: 1}} container spacing={2} p={2}>
                            {filteredAlbums.map((album, index) => (
                                <Grid item xs={12} sm={12} md={12} lg={12} key={`${album.id}-${activeTab}`}>
                                    <motion.div
                                        initial="hidden"
                                        animate="visible"
                                        variants={animationVariants}
                                        transition={{ duration: 0.5, delay: index * 0.2 }}
                                    >
                                        <CustomCard>
                                            <Box display="flex" className={"row"}>
                                                <div className={"col-12 col-md-12 col-lg-4 col-xl-3 d-flex justify-content-center align-items-center p-3"}>
                                                    <img onClick={() => {setOpenImage(true); setImageLink(album.img_name); }} src={album.img_name} width="100%" alt="" className={"rounded"} />
                                                </div>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} className={"col-12 col-md-12 col-lg-8 col-xl-7"}>
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5"
                                                                    className={"d-flex justify-content-between"}
                                                                    component="div">
                                                            <Link to={`/album/${album.code}`}>{album.name}</Link>
                                                        </Typography>
                                                        <Typography variant="body1" color="text.secondary">
                                                            {album.artist}
                                                        </Typography>
                                                        <br/>
                                                        <Typography variant="p" color="text.secondary">
                                                            <b className="text-index">Дата
                                                                релиза:</b> {album.reliz_date.substring(0, 10)}
                                                        </Typography>
                                                        <br/>
                                                        <Typography variant="p" color="text.secondary">
                                                            <b className="text-index">UPC:</b> {album.upc}
                                                        </Typography>
                                                        <br/>
                                                        <Typography variant="p" color="text.secondary">
                                                            <b className="text-index">Лейбл:</b> {album.copyright}
                                                        </Typography>
                                                    </CardContent>
                                                </Box>
                                                <Box className={"d-flex align-items-top col-12 col-md-12 col-lg-12 mt-3 col-xl-2 mb-3 p-2 pr-4"} >
                                                    <div className={"p-2 pr-4 w-100"}>
                                                        {
                                                            album.status_added === 0 && album.admin_comment ? (
                                                                <Tooltip title={`Комментарий админа: ${album.admin_comment}`}>
                                                                    <Button style={{height: "min-content"}} className={`border w-100 rounded border-${album.status_added === 0 ? 'secondary' : album.status_added === 1 ? 'warning' : album.status_added === 2 ? 'success' : 'danger'}`}>
                                                                        {statusDescriptions[album.status_added] || 'Неизвестный статус'}
                                                                    </Button>
                                                                </Tooltip>
                                                            ) : (
                                                                <Button style={{height: "min-content"}} className={`border w-100 rounded border-${album.status_added === 0 ? 'secondary' : album.status_added === 1 ? 'warning' : album.status_added === 2 ? 'success' : 'danger'}`}>
                                                                    {statusDescriptions[album.status_added] || 'Неизвестный статус'}
                                                                </Button>
                                                            )
                                                        }
                                                        <div className="mt-4 d-flex justify-content-end align-items-center">
                                                            <Link to={`/album/${album.code}`}>
                                                                <Tooltip title="Редактировать">
                                                                    <SvgIcon>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                                             fill="currentColor"
                                                                             className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                            <path
                                                                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                                                            <path fillRule="evenodd"
                                                                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                                                                        </svg>
                                                                    </SvgIcon>
                                                                </Tooltip>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </Box>
                                            </Box>
                                        </CustomCard>
                                    </motion.div>
                                </Grid>
                            ))}
                            <Backdrop
                                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                open={openImage}
                                onClick={handleCloseImage}
                            >
                                <div className="image-backdrop" style={{position: 'relative'}}>
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleCloseImage}
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            color: '#fff',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                            }
                                        }}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                    <img
                                        onClick={() => setOpenImage(true)}
                                        src={imageLink}
                                        alt="imageAlbum"
                                        width="100%"
                                        className={"rounded"}
                                    />
                                </div>
                            </Backdrop>
                        </Grid>
                    ) : (
                        <>
                            {myAlbums.length < 1 ? (
                                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                                    <Box textAlign="center">
                                        <Typography variant="h4" gutterBottom>
                                            У вас нет ни одного альбома.
                                        </Typography>
                                        <Button component={Link} to="/add/album" className="btn mainButton">
                                            Создайте альбом
                                        </Button>
                                    </Box>
                                </Box>
                            ) : (
                                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                                    <Box textAlign="center">
                                        <Typography variant="h4" gutterBottom>
                                            Нет альбома в этой категории
                                        </Typography>
                                        <Button component={Link} to="/add/album" className="btn mainButton">
                                            Создайте альбом
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default MyAlbumsList;
