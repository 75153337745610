import React, { useState, useEffect } from 'react';

import FileInput from '../components/Inputs/FileInput';
import ImageCropper from './ImageCropper';

const AddCropperAlbum = (props) => {

    const [image, setImage] = useState("");
    const [currentBlock, setCurrentBlock] = useState("choose-img");
    const [imgAfterCrop, setImgAfterCrop] = useState("");
    const [error, setError] = useState("");

    const onImageSelected = (selectedImg) => {
        setImage(selectedImg);
        setCurrentBlock("img-crop");
        setError("");
    }

    const onCropDone = (imgCroppedArea) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;

        const context = canvasEle.getContext("2d");

        let imageObj1 = new Image();
        imageObj1.src = image;
        imageObj1.onload = function() {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height,
            );

            const dataURL = canvasEle.toDataURL("image/jpeg");

            setImgAfterCrop(dataURL);
            props.setImage(dataURL);
            setCurrentBlock("cropped-img");
        }
    }

    const onCropCancel = () =>  {
        setCurrentBlock("choose-img");
        setImage("");
        setError("");
    }

    useEffect(() => {
        if(props.image) {
            setCurrentBlock("choose-new-image-with-already");
        }
    }, [props.image]);

    return (
        <>
            {currentBlock === 'choose-img' ? (
                <FileInput onImageSelected={onImageSelected} setError={setError} inputText={(<>Выберите изображение релиза<br/>(.jpg .png)</>)} />
            ) : currentBlock === "img-crop" ? (
                <ImageCropper image={image} onCropDone={onCropDone} onCropCancel={onCropCancel} />
            ) : currentBlock === "choose-new-image-with-already" ? (
                <div className="d-flex">
                    <div className="p-4">
                        <FileInput onImageSelected={onImageSelected} setError={setError} inputText={(<>Обновить изображение релиза<br/>(.jpg .png)</>)} />
                    </div>

                    <div className="p-4">
                        <img src={props.image} width="200px" className="rounded" id="cropped-img" alt="" />
                    </div>
                </div>
            ) : (
                <div className="d-flex">
                <div className="p-4">
                    <FileInput onImageSelected={onImageSelected} setError={setError} inputText={(<>Обновить изображение релиза<br/>(.jpg .png)</>)} />
                </div>

                <div className="p-4">
                    <img src={imgAfterCrop} width="200px" className="rounded" id="cropped-img" alt="" />
                </div>
                </div>
            )}
            {
                error && (
                    <p className="text-danger mt-2">{error}</p>
                )
            }
        </>
    )
}

export default AddCropperAlbum