import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../API/UserContext';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

import { Link } from 'react-router-dom';

const Login = () => {
    const { loginUser, loginUserTelegram, wait, loggedInCheck } = useContext(UserContext);
    const [redirect, setRedirect] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [webapp, setWebApp] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        rememberMe: false,
        confirmRules: false,
    });

    const TelegramWindow = window.Telegram.WebApp;

    React.useEffect(() => {
        if (typeof(TelegramWindow.initDataUnsafe.user) !== 'undefined') {
            setWebApp(true);
        }
    }, []);

    useEffect(() => {
        document.title = "Muzilok | Авторизация";
    }, []);

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        const loggedTelegram = async () => {

            const data = await loginUserTelegram();
            if (data._token) {
                setRedirect('Переадреация...');
                await loggedInCheck();
                return;
            }
            setErrMsg(data.message);
        }
        if (typeof(TelegramWindow.initDataUnsafe.user) !== 'undefined') {
            loggedTelegram();
        }

    }, []);

    const submitForm = async (e) => {
        e.preventDefault();

        if (!formData.confirmRules) {
            setErrMsg('При авторизации вы должны согласиться с правилами сообщества!');
            return;
        }

        const data = await loginUser(formData);
        if (data._token) {
            e.target.reset();
            setRedirect('Переадреация...');
            await loggedInCheck();
            return;
        }
        setErrMsg(data.message);
    }

    const handleSwitchChange = (event) => {
        setFormData({ ...formData, rememberMe: event.target.checked });
    };

    const handleSwitchConfirmRulesChange = (event) => {
        setFormData({ ...formData, confirmRules: event.target.checked });
    };

    // const MusicNote = () => (
    //     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-music-note" viewBox="0 0 16 16">
    //       <path d="M9 13c0 1.105-1.12 2-2.5 2S4 14.105 4 13s1.12-2 2.5-2 2.5.895 2.5 2"/>
    //       <path fillRule="evenodd" d="M9 3v10H8V3z"/>
    //       <path d="M8 2.82a1 1 0 0 1 .804-.98l3-.6A1 1 0 0 1 13 2.22V4L8 5z"/>
    //     </svg>
    // );

    // const notes = Array.from({ length: 100 }).map((_, index) => ({
    //     id: index,
    //     style: {
    //       left: `${Math.random() * 100}%`,
    //       top: `${Math.random() * 100}%`,
    //       animationDelay: `${Math.random() * 5}s`, // Случайная задержка от 0 до 5 секунд
    //     },
    // }));

    return (
        <>
            <div className="w-100 text-center p-3 mt-4">
                <h3 id="pageName" className="concert-one-regular text-uppercase">muzilok | авторизация</h3>
            </div>
            <div className="login-page">
                <div className="form note-background">
                    <form className="login-form" onSubmit={submitForm} autoComplete="off">
                        <h5>Войдите в аккаунт</h5>
                        <input type="text" name="username" className='mt-3' onChange={onChangeInput} placeholder="Логин"
                               id="username" value={formData.username} required/>
                        <input type="password" name="password" onChange={onChangeInput} placeholder="Пароль"
                               id="password" value={formData.password} required/>
                        <small className="mb-4 w-100 text-left mt-4">
                            <Switch defaultChecked={formData.rememberMe}
                                    onChange={handleSwitchChange}/> Запомнить меня
                        </small>
                        <small className="mb-4 w-100 text-left d-inline-block mt-4">
                            <Switch defaultChecked={formData.confirmRules}
                                    onChange={handleSwitchConfirmRulesChange}/> Соглашаюсь с <a href="/privacy" target="_blank">правилами</a>
                        </small>

                        {errMsg &&
                            <Alert variant="outlined" severity="error">
                                {
                                    errMsg === 'bot' ? (
                                        <>
                                            Ваш аккаунт не привязан к Telegram боту.
                                            Привяжите его в боте <a href="https://t.me/muzilokbot">@muzilokbot</a>
                                        </>
                                    ) : (
                                        <>
                                            {errMsg}
                                        </>
                                    )
                                }
                            </Alert>
                        }
                        {
                            webapp && (
                                <p>Данное окно используется для привязки аккаунта MUZILOK к Telegram аккаунту.</p>
                            )
                        }
                        {redirect ?
                            <div className="w-100 p-4 d-flex justify-content-center">
                                <CircularProgress/>
                            </div>
                            : (<></>)
                        }

                        <Button type="submit" className='btn mainButton mt-2' disabled={wait}>
                            {
                                wait ? (
                                    <CircularProgress/>
                                ) : (
                                    <>
                                        {
                                            webapp ? (
                                                <>Привязать аккаунт</>
                                            ) : (
                                                <>Войти</>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Button>

                        <br />

                        {
                            webapp ? (
                                <small className={"mt-4"}>
                                    <Link className={"text-center"} to={'/signup'}>Зарегистрироваться</Link>
                                </small>
                            ) : null
                        }

                    </form>
                </div>
                {/* {notes.map(note => (
            <div key={note.id} className="music-note" style={note.style}>
                <MusicNote />
            </div>
        ))} */}
            </div>

        </>
    )
}

export default Login;
