import React, { useState, useEffect, useContext } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { UserContext } from '../../API/UserContext';
import Button from '@mui/material/Button';

function getToken() {
    const token = localStorage.getItem('_token');

    if (!token) {
        return sessionStorage.getItem('_token');
    }
    return token;
}

const AlbumPlaysChart = () => {
    const { user } = useContext(UserContext);
    const Token = getToken();

    const [chartType, setChartType] = useState('bar');
    const [albumChartType, setAlbumChartType] = useState('bar');

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            label: 'Количество прослушиваний',
            data: [],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1,
            barPercentage: 0.1,
            categoryPercentage: 1.0
        }]
    });

    const [albumChartData, setAlbumChartData] = useState({
        labels: [],
        datasets: [{
            label: 'Количество альбомов',
            data: [],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1,
            barPercentage: 0.1,
            categoryPercentage: 1.0
        }]
    });

    const [albumData, setAlbumData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const playsResponse = await fetch(`https://api.muzilok.ru/getPlaysData?username=${user.username}&_token=${Token}`);
                const playsData = await playsResponse.json();
                const playsLabels = playsData.map(item => item.title + " (" + item.total_plays + ")");
                const playCounts = playsData.map(item => item.total_plays);

                const albumsResponse = await fetch(`https://api.muzilok.ru/getAlbumsDataChart?username=${user.username}&_token=${Token}`);
                const albumsData = await albumsResponse.json();

                const mergedAlbumData = mergeAlbumData(albumsData);

                setChartData({
                    labels: playsLabels,
                    datasets: [{
                        ...chartData.datasets[0],
                        data: playCounts
                    }]
                });
                setAlbumChartData({
                    labels: mergedAlbumData.labels,
                    datasets: [{
                        ...albumChartData.datasets[0],
                        data: mergedAlbumData.data
                    }]
                });
                setAlbumData(albumsData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const mergeAlbumData = (albumsData) => {
        const monthMap = {};

        albumsData.forEach(item => {
            if (!monthMap[item.month]) {
                monthMap[item.month] = [];
            }
            monthMap[item.month].push(item.title);
        });

        const labels = Object.keys(monthMap);
        const data = labels.map(label => monthMap[label].length);

        return { labels, data };
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false // Скрытие легенды
            },
            tooltip: {
                enabled: true, // Включение подсказок
                callbacks: {
                    afterLabel: function(context) {
                        const month = context.label;
                        const albums = albumData.filter(item => item.month === month).map(item => item.title);
                        return albums.length > 0 ? 'Альбомы: ' + albums.join(', ') : '';
                    }
                }
            },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
                formatter: (value, context) => {
                    return context.chart.data.datasets[0].data[context.dataIndex];
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true
            },
            x: {
                maxBarThickness: 30 // Максимальная толщина столбца
            }
        },
        maintainAspectRatio: false
    };

    const doughnutOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false // Скрытие легенды
            },
            tooltip: {
                enabled: true, // Включение подсказок
                callbacks: {
                    afterLabel: function(context) {
                        const month = context.label;
                        const albums = albumData.filter(item => item.month === month).map(item => item.title);
                        return albums.length > 0 ? 'Альбомы: ' + albums.join(', ') : '';
                    }
                }
            },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
                formatter: (value, context) => {
                    return context.chart.data.datasets[0].data[context.dataIndex];
                }
            }
        },
        maintainAspectRatio: false
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <h3 className="mb-4">Статистика прослушиваний по всем релизам</h3>
                <div>
                    <Button onClick={() => setChartType('bar')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                            <path
                                d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z"/>
                        </svg>
                    </Button>
                    <Button onClick={() => setChartType('doughnut')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-pie-chart-fill" viewBox="0 0 16 16">
                            <path
                                d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778zM8.5.015V7.5h7.485A8 8 0 0 0 8.5.015"/>
                        </svg>
                    </Button>
                </div>
            </div>
            <div style={{width: '100%', height: 400}}>
                {chartType === 'bar' ? (
                    <Bar style={{height: 400}} data={chartData} options={options}/>
                ) : (
                    <Doughnut style={{height: 400}} data={chartData} options={doughnutOptions}/>
                )}
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <h3 className="mb-4">Количество выпущенных альбомов по месяцам</h3>
                <div>
                    <Button onClick={() => setAlbumChartType('bar')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                            <path
                                d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z"/>
                        </svg>
                    </Button>
                    <Button onClick={() => setAlbumChartType('doughnut')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-pie-chart-fill" viewBox="0 0 16 16">
                            <path
                                d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778zM8.5.015V7.5h7.485A8 8 0 0 0 8.5.015"/>
                        </svg>
                    </Button>
                </div>
            </div>
            <div style={{width: '100%', height: 400}}>
                {albumChartType === 'bar' ? (
                    <Bar style={{height: 400}} data={albumChartData} options={options}/>
                ) : (
                    <Doughnut style={{height: 400}} data={albumChartData} options={doughnutOptions}/>
                )}
            </div>
        </>
    );
};

export default AlbumPlaysChart;
