import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import * as React from 'react';
import axios from "axios";
import { TrackContext } from "../../API/TrackContext";
import AlbumSess from '../../API/AlbumSess';
import AddTrackModal from "../../blocks/AddTrackModal";
import { usePlayer } from './../../Context/LargePlayer';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import EditTrackModal from '../../blocks/EditTrackModal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import SaveFilesRule from './../../blocks/SaveFilesRule';

import PageStat from  './../../components/Statistic/Page';
import HelpChat from './../../components/Chat/Help';

export const Axios = axios.create({
    baseURL: 'https://api.muzilok.ru/',
});


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography variant={'p'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function HtmlTab({ label, ...other }) {
    return (
        <Tab
            {...other}
            label={
                <Typography component="span" dangerouslySetInnerHTML={{ __html: label }} />
            }
        />
    );
}

function BasicTabs({ value, setValue }) {

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', overflowX: 'auto' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    <Tab label="Альбом" {...a11yProps(0)} />
                    {/*<Tab label="Треки" {...a11yProps(1)} />*/}
                    <Tab label="Подтверждение авторских прав" {...a11yProps(1)} />
                    <HtmlTab label="Модерация <small class='text-danger ml-2'>NEW</small>" {...a11yProps(2)} />
                    <HtmlTab label="Статистика <small class='text-danger ml-2'>NEW</small>" {...a11yProps(3)} />
                </Tabs>
            </Box>
        </Box>
    );
}

const PlayButton = ({ url, artist, title, img_name, albumid, tracks, setTrackList, album }) => {
    const { playTrack, togglePlay, isPlaying, trackUrl } = usePlayer();

    const newTrackList = tracks.map(track => ({
        url: "https://api.muzilok.ru/uploads/tracks/" + track.src,
        artist: track.artist,
        title: track.name,
        imgName: album?.img_name,
    }));

    const handleClick = () => {
        if (url !== trackUrl) {
            setTrackList(newTrackList);
            playTrack(url, title, artist, img_name);
        } else {
            setTrackList(newTrackList);
            togglePlay(title, artist, img_name);
        }
    };

    return (
        <span onClick={handleClick} className="c-pointer playButton">
        {isPlaying && url === trackUrl ?
            (<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pause" viewBox="0 0 16 16"><path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5"/></svg>)
            :
            (<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-play" viewBox="0 0 16 16"><path d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z"/></svg>)}
      </span>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography variant={'p'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const AlbumView = () => {

    const params = useParams();
    const { getMyAlbum, deleteTrack } = useContext(TrackContext);
    const albumId = params.albumId;
    const [album, setAlbum] = useState(null);
    const { setTrackList, setAlbumId, trackList, albumPlayerID } = usePlayer();
    const [openImage, setOpenImage] = useState(false);

    const theme = useTheme();

    const handleCloseImage = () => {
        setOpenImage(false);
    }

    useEffect(() => {
        var title = 'Альбом "' + album?.name + '" | MUZILOK';
        document.title = title;
    }, [album?.name]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getMyAlbum({ albumId });
                AlbumSess.setData(response);
                setAlbum(response);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching album:", error);
            }
        };

        fetchData();
    }, [albumId, getMyAlbum]);

    const [tracks, setTracks] = useState([]);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [confirmDeleteModalName, setConfirmDeleteModalName] = useState(false);
    const [confirmDeleteModalID, setConfirmDeleteModalID] = useState(false);
    const [loading, setLoading] = useState(true);

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.muzilok.ru/getTracks?id=' + albumId + "&_token=" + localStorage.getItem('_token'));
                const jsonData = await response.json();
                setTracks(jsonData);
                const newTrackList = jsonData.map(track => ({
                    url: "https://api.muzilok.ru/uploads/tracks/" + track.src,
                    artist: track.artist,
                    title: track.name,
                    imgName: album?.img_name,
                }));
                if(trackList.length === 0 && albumPlayerID !== albumId) {
                    setTrackList(newTrackList);
                    setAlbumId(albumId);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [albumId, setTrackList, album, setAlbumId, trackList, albumPlayerID]);

    const deleteTrackModal = (name, id) => {
        setConfirmDeleteModalName(name);
        setConfirmDeleteModalID(id);
        setConfirmDeleteModal(true);
    }

    const handleCloseDeleteTrackModal = () => {
        setConfirmDeleteModal(false);
    };

    const handleDeleteTrack = async (id) => {
        await deleteTrack({
            trackid: id,
        });

        handleCloseDeleteTrackModal();
    }

    const [wait, setWait] = useState(false);
    const [openSharedLink, setOpenSharedLink] = useState(false);

    const createSharedLink = async () => {
        setWait(true);
        try {
            const response = await fetch('https://api.muzilok.ru/createSharedAlbumLink?id=' + albumId + "&_token=" + localStorage.getItem('_token'));
            const jsonData = await response.json();

            if(jsonData.success) {
                setOpenSharedLink(true);
                album.shared = 1;
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setWait(false);
    }

    const [copy, setCopy] = React.useState(false);

    const handleCopyBL = () => {
        navigator.clipboard.writeText("https://link.muzilok.ru/" + album?.code)
            .then(() => setCopy(true))
            .catch(err => console.error('Ошибка при копировании:', err));
    };

    const statusDescriptions = {
        0: '<div class="status-alert-album d-flex align-items-center"><b class="status-album-text text-info">черновик</b></div>',
        1: '<div class="status-alert-album d-flex align-items-center"><b class="status-album-text text-warning">на модерации</b></div>',
        2: '<div class="status-alert-album d-flex align-items-center"><b class="status-album-text text-success">опубликован</b></div>',
        3: '<div class="status-alert-album d-flex align-items-center"><b class="status-album-text text-danger">на удалении</b></div>',
        4: '<div class="status-alert-album d-flex align-items-center"><b class="status-album-text text-danger">удален</b></div>'
    };

    const [order, setOrder] = useState(tracks.length > 0 ? album?.orderTrackList !== null ? album?.orderTrackList.split('-') : Object.values(tracks).map(track => track.id) : []);

    useEffect(() => {
        setOrder(tracks.length > 0 ? album?.orderTrackList !== null ? album?.orderTrackList.split('-') : Object.values(tracks).map(track => track.id) : []);
    }, [tracks, album]);

    const moveRow = async (dragIndex, hoverIndex) => {
        const newOrder = [...order];
        const dragId = newOrder[dragIndex];
        newOrder.splice(dragIndex, 1);
        newOrder.splice(hoverIndex, 0, dragId);
        setOrder(newOrder);

        await fetch('https://api.muzilok.ru/changeOrderTrackList?code=' + albumId + "&_token=" + localStorage.getItem('_token') + "&order=" + newOrder.join('-'));
    };

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('text/plain', index);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, hoverIndex) => {
        const dragIndex = e.dataTransfer.getData('text/plain');
        moveRow(dragIndex, hoverIndex);
    };

    const [page, setPage] = useState(0);

    const handleChangePage = (index) => {
        setPage(index)
    }

    return (
        <>
            {
                album && !loading ? (
                    <div className="w-100 m-auto">

                        <p className="text-muted">
                            <Link className="text-muted" to="/albums">Мои альбомы </Link> / "{album?.name}"
                        </p>

                        <div className="w-100 mb-2 mt-2">
                            <BasicTabs value={page} setValue={setPage} />
                        </div>

                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={page}
                            onChangeIndex={handleChangePage}
                        >
                            <TabPanel value={page} index={0} dir={theme.direction}>
                                <div className="card">
                                    <div className="card-body rounded">
                                        <div className="row">
                                            <div className="col-12 col-md-3 col-xl-3 p-2">
                                                <img
                                                    onClick={() => setOpenImage(true)}
                                                    src={"https://api.muzilok.ru/uploads/albums_photos/" + (album?.img_name.toString())}
                                                    className="rounded c-pointer"
                                                    alt="imageAlbum"
                                                    width="100%"/>
                                            </div>
                                            <div className="col-12 col-md-9 col-xl-3 row pt-2">
                                                <div className="col-12 d-flex justify-content-between">
                                                    <div>
                                                        <h2>{album?.name}</h2>
                                                        <h4 className="mt-3">{album?.artist}</h4>
                                                        {
                                                            album?.second_artist && (
                                                                <h5 className="mt-3">feat. {album?.second_artist}</h5>
                                                            )
                                                        }
                                                        <p className="mt-3 mb-2">Тип релиза: <b>{album?.type}</b></p>
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: statusDescriptions[album?.status_added]}}/>
                                                        {
                                                            album?.status_added == 1 && album?.platform_status == 1 && (
                                                                <p className={"text-success p-2 border border-success mt-2 rounded"}>Альбом отправлен модерироваться на площадки</p>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-xl-6 row pt-2  ">
                                                <div className="col-12 col-md-6">
                                                    <h5><b className={"text-index"}>Жанр:</b> {album?.zhanr}</h5>
                                                    <h5 className="mt-2"><b className={"text-index"}>Дата
                                                        релиза:</b> {album?.reliz_date.substring(0, 10)}</h5>
                                                    {
                                                        album?.predate && (
                                                            <div className="col-6 col-md-6">
                                                                <h5><b className={"text-index"}>Дата
                                                                    предзаказа:</b> {album?.predate.substring(0, 10)}</h5>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <h5>
                                                        <b className={"text-index"}>UPC:</b> {album?.upc}
                                                    </h5>
                                                    <h5 className="mt-2"><b className={"text-index"}>Label:</b> {album?.copyright}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <Link to={`/edit/album/${albumId}`}>
                                            <Tooltip title="Редактировать">
                                                <IconButton>
                                                    <SvgIcon>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                             fill="currentColor"
                                                             className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                            <path
                                                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                                            <path fillRule="evenodd"
                                                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                                                        </svg>
                                                    </SvgIcon>
                                                </IconButton>
                                            </Tooltip>
                                        </Link>
                                        <br/>
                                        {
                                            album?.status_added === 2 && album?.shared < 1 &&
                                            (
                                                <Button variant="contained" disabled={wait} className="mainButton"
                                                        onClick={createSharedLink}>
                                                    {
                                                        wait ? (
                                                            <CircularProgress style={{height: 20, width: 20}}/>
                                                        ) : (
                                                            <>Создать смарт ссылку</>
                                                        )
                                                    }
                                                </Button>
                                            )
                                        }
                                        {
                                            album?.status_added === 2 && album?.shared > 0 && (
                                                <Button variant="contained" disabled={wait} className="mainButton"
                                                        onClick={createSharedLink}>
                                                    {
                                                        wait ? (
                                                            <CircularProgress style={{height: 20, width: 20}}/>
                                                        ) : (
                                                            <>Открыть или обновить смарт ссылку</>
                                                        )
                                                    }
                                                </Button>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="w-100 mt-4 table-scroll card card-body rounded">
                                    <h3>Трек-лист</h3>
                                    <table className="table table-striped table-dark w-100">
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col"></th>
                                            <th scope="col">Название</th>
                                            <th scope="col">Версия</th>
                                            <th scope="col">Артист</th>
                                            <th scope="col">Автор</th>
                                            <th scope="col">Композитор</th>
                                            <th scope="col">Ненормативная лексика</th>
                                            <th scope="col">ISRC</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tracks?.length === 0 ? (
                                            <tr>
                                                <td colSpan="10" className="text-center">
                                                    В плейлисте нету ни одного трека
                                                </td>
                                            </tr>
                                        ) : ""}
                                        {
                                            order && (
                                                <>
                                                    {order.map((trackId, index) => {
                                                        const track = tracks.find(track => track.id === Number(trackId));
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {
                                                                    track !== undefined && (
                                                                        <tr key={trackId}
                                                                            draggable
                                                                            onDragStart={(e) => handleDragStart(e, index)}
                                                                            onDragOver={handleDragOver}
                                                                            onDrop={(e) => handleDrop(e, index)}>
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td><PlayButton
                                                                                url={"https://api.muzilok.ru/uploads/tracks/" + track.src}
                                                                                artist={track.artist} title={track.name}
                                                                                img_name={album?.img_name} tracks={tracks}
                                                                                setTrackList={setTrackList} album={album}/></td>
                                                                            <td>{track.name}</td>
                                                                            <td>{track.version}</td>
                                                                            <td>{track.artist} {track.second_artist ? `(feat. ${track.second_artist})` : ''}</td>
                                                                            <td>{track.author}</td>
                                                                            <td>{track.composer}</td>
                                                                            <td>{track.vocabulary === 'yes' ? 'Да' : 'Нет'}</td>
                                                                            <td>{track.ISRC}</td>
                                                                            <td>
                                                                                <Tooltip title="Удалить"
                                                                                         onClick={() => deleteTrackModal(track.name, track.id)}>
                                                                                    <IconButton>
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="25" height="25"
                                                                                            fill="rgb(211,26,34)"
                                                                                            className="bi bi-trash-fill"
                                                                                            viewBox="0 0 16 16">
                                                                                            <path
                                                                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                                                                                        </svg>
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <EditTrackModal trackID={track.id}
                                                                                                albumid={album?.id}/>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </>
                                            )
                                        }
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colSpan="10" className="text-center">
                                                <AddTrackModal albumid={album?.id} artist={album?.artist}
                                                               secondArtist={album?.second_artist}/>
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </TabPanel>
                            <TabPanel value={page} index={1} dir={theme.direction}>
                                <div className="card rounded mt-2" dir={theme.direction}>
                                    <div className="card-body rounded p-4">
                                        <h3>Подтверждение авторских прав</h3>
                                        <p className="text-muted">Договор с битмейкером или видео проекта в
                                            секвенсоре</p>
                                        <SaveFilesRule tracks={tracks} albumId={albumId}/>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={page} index={2} dir={theme.direction}>
                                <div className="row">
                                    <HelpChat album={album}/>
                                </div>
                            </TabPanel>
                            <TabPanel value={page} index={3} dir={theme.direction}>
                                <div dir={theme.direction}>
                                    {
                                        album?.upc !== '' && album?.upc !== null && album?.upc !== undefined ? (
                                            <PageStat upc={album?.upc} playsData={album?.playsData}
                                                      playsDataDays={album?.playsDataDays}
                                                      lastStatUpdate={album?.lastStatUpdate}/>
                                        ) : (
                                            <h4 className="mt-4">Статистика доступна только после выхода релиза</h4>
                                        )
                                    }
                                </div>
                            </TabPanel>
                        </SwipeableViews>

                        {
                            confirmDeleteModal && (
                                <Dialog
                                    open={confirmDeleteModal}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleCloseDeleteTrackModal}
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle>{"Вы действительно хотите удалить трек?"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            Если вы удалите трек "{confirmDeleteModalName}", то вся информация о нем
                                            будет
                                            удалена.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseDeleteTrackModal}>Отмена</Button>
                                        <Button onClick={() => handleDeleteTrack(confirmDeleteModalID)}>Удалить</Button>
                                    </DialogActions>
                                </Dialog>
                            )
                        }
                        <Backdrop
                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={openImage}
                            onClick={handleCloseImage}
                        >
                            <div className="image-backdrop" style={{position: 'relative'}}>
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseImage}
                                    sx={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 8,
                                        color: '#fff',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        }
                                    }}
                                >
                                    <CloseIcon/>
                                </IconButton>
                                <img
                                    onClick={() => setOpenImage(true)}
                                    src={"https://api.muzilok.ru/uploads/albums_photos/" + (album?.img_name.toString())}
                                    alt="imageAlbum"
                                    width="100%"
                                    className={"rounded"}
                                />
                            </div>
                        </Backdrop>
                        <React.Fragment>
                            <Dialog
                                open={openSharedLink}
                                onClose={() => setOpenSharedLink(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"Копировать смарт ссылку"}
                                </DialogTitle>
                                <DialogContent>

                                    <div className="p-2 d-flex align-items-center justify-content-between">
                                        <b style={{fontSize: 14}}>https://link.muzilok.ru/{album?.code}</b>
                                        <Button onClick={handleCopyBL} variant="text" color="primary">
                                            {
                                                copy ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                         fill="currentColor" className="bi bi-clipboard2-check"
                                                         viewBox="0 0 16 16">
                                                        <path
                                                            d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z"/>
                                                        <path
                                                            d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z"/>
                                                        <path
                                                            d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                                                    </svg>
                                                ) : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                         fill="currentColor"
                                                         className="bi bi-clipboard2" viewBox="0 0 16 16">
                                                        <path
                                                            d="M3.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H12a.5.5 0 0 1 0-1h.5A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1H4a.5.5 0 0 1 0 1z"/>
                                                        <path
                                                            d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5"/>
                                                    </svg>
                                                )
                                            }
                                        </Button>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpenSharedLink(false)}>Закрыть</Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                    </div>
                ) : (
                    <>
                        {
                            loading ? (
                                <Stack spacing={1}>
                                    <div className="w-100 row mb-4">
                                        <div className="col-2"><Skeleton style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}} variant="circular" width={200} height={200} /></div>
                                        <div className="col-10"><Skeleton style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}} variant="rounded" width="100%" height={200} /></div>
                                    </div>

                                    <Skeleton style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}} variant="rounded" width={410} height={60} />
                                    <Skeleton style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}} variant="rounded" width={410} height={60} />
                                </Stack>
                            ) : (
                                <div className="w-100 card card-body rounded p-4">
                                    <h4>Альбом с кодом "{albumId}" не найден в базе, или вы не его владелец.</h4>
                                </div>
                            )
                        }
                    </>
                )
            }
        </>
    );
}

export default AlbumView;