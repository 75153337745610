import { useContext, useState, useEffect } from 'react';
import { TrackContext } from '../../API/TrackContext';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../API/UserContext';
import HeaderModal from '../../components/Blocks/HeaderModal';
import AddCropperAlbum from '../../blocks/AddCropperAlbum';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArtistSelect from './../../components/Blocks/SelectArtist';
import { motion } from 'framer-motion';

const AddAlbum = () => {
    const { user } = useContext(UserContext);
    const [confirm, setConfirm] = useState();
    const currentDate = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Moscow"}));
    const endDate = new Date(user.subscribe_date_end);
    const startDate = new Date(user.subscribe_date_start);
    const title = 'Создание релиза';

    useEffect(() => {
        document.title = title;
    }, []);

    const { createAlbum, wait } = useContext(TrackContext);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name_track: '',
        version_track: '',
        artist_track: [],
        second_artist_track: [],
        zhanr_track: '0',
        date_track: '',
        predate_track: '',
        type_track: '0',
        lang_track: '0',
        copyright_track: 'muzilok',
        upc_track: '',
        image: '',
    });
    const [inputError, setInputError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [artistName, setArtistName] = useState('');
    const [selectedTypeArtist, setSelectedTypeArtist] = useState('main');
    const [imageAlbum, setImageAlbum] = useState('');

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        selectMain();
    };

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        if(document.getElementById("cropped-img")) {
            setFormData({
                ...formData,
                image: imageAlbum,
            });
        }
    }, [imageAlbum]);

    const submitForm = async (e) => {
        handleOpenLoading();
        e.preventDefault();

        const data = await createAlbum(formData);

        if (data.success === 1) {
            handleCloseLoading();
            e.target.reset();
            setSuccessMsg('Альбом успешно добавлен');
            setErrMsg(false);
            return navigate('/album/' + data.id);
        }
        else if (!data.success && data.message) {
            handleCloseLoading();
            setSuccessMsg(false);
            setErrMsg(data.message);
            if(data.type === 'input') {
                setInputError(true);
            }
        }
    };

    const selectMain = () => setSelectedTypeArtist('main');
    const selectFeat = () => setSelectedTypeArtist('feat');

    const addArtist = () => {
        if (artistName.trim() === '') {
            alert('Введите имя артиста.');
            return;
        }

        if (selectedTypeArtist === 'main') {
            setFormData(prevFormData => ({
                ...prevFormData,
                artist_track: [...prevFormData.artist_track, artistName]
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                second_artist_track: [...prevFormData.second_artist_track, artistName]
            }));
        }

        setArtistName('');
        closeModal();
    };

    const removeArtist = (indexToRemove) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            artist_track: prevFormData.artist_track.filter((_, index) => index !== indexToRemove)
        }));
    };

    const removeArtistSecond = (indexToRemove) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            second_artist_track: prevFormData.second_artist_track.filter((_, index) => index !== indexToRemove)
        }));
    };

    const [openLoading, setOpenLoading] = useState(false);

    const handleCloseLoading = () => {
        setOpenLoading(false);
    };
    const handleOpenLoading = () => {
        setOpenLoading(true);
    };

    const animationVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <>
            <div className="w-100 m-auto">
                <h3 className="mb-4">Создание релиза</h3>
                { !(endDate > currentDate && startDate < currentDate && user.subscribe_type > 0) && (user.count_single > 0 || user.count_ep > 0 || user.count_album > 0) ? (
                    <p>
                        <b className="text-danger">У вас остались только разовые тарифы</b> <br />
                        <Link to="/settings/subscribe">
                            Узнайте о ваших разовых тарифах по ссылке
                        </Link>
                    </p>
                ) : (
                    <></>
                )}
                { !(endDate > currentDate && startDate < currentDate && user.subscribe_type > 0) && user.count_single < 1 && user.count_ep < 1 && user.count_album < 1 ? (
                    <p>
                        <b className="text-danger">
                            У вас нету ни одного бесплатного тарифа и подписки
                        </b>
                        <br />
                        <Link to="/settings/subscribe">
                            Узнайте о ваших тарифах по ссылке
                        </Link>
                    </p>
                ) : (
                    <></>
                )}
                <div className="row">
                    <form onSubmit={submitForm} className="col-12 col-md-12">
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={animationVariants}
                            transition={{ duration: 0.5 }}
                            className="card"
                        >
                            <div className="card-body rounded p-4">
                                <div className="p-2">
                                    <h6>Основная информация</h6>
                                    <p className="text-muted">Заполните общую информацию по вашему релизу</p>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-4 col-md-6 mt-2 p-2">
                                        <label className={`${inputError && !formData.name_track ? 'text-danger' : 'text-muted'}`} htmlFor="name_track">Название релиза <small className={`${inputError && !formData.name_track ? 'text-danger' : 'text-muted'}`}>*</small></label>
                                        <input className={`form-control ${inputError && !formData.name_track ? 'border-danger' : ''}`} placeholder="Название релиза" id="name_track" name="name_track" onChange={onChangeInput} value={formData.name_track} />
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-6 mt-2 p-2">
                                        <label className="text-muted" htmlFor="version_track">Версия релиза</label>
                                        <input className="form-control" placeholder="Версия релиза" name="version_track" value={formData.version_track} onChange={onChangeInput} />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 col-lg-4 col-md-6 p-2">
                                        <label htmlFor="type_track" className={`${inputError && formData.type_track == '0' ? 'text-danger' : 'text-muted'}`}>Тип релиза <small className={`${inputError && formData.type_track == '0' ? 'text-danger' : 'text-muted'}`}>*</small></label>
                                        <select id="type_track" className={`form-control text-muted w-100 ${inputError && formData.type_track == '0' ? 'border-danger' : ''}`} name="type_track" value={formData.type_track} onChange={onChangeInput}>
                                            <option value='0'>Выберите тип релиза</option>
                                            <option value="Album">Album</option>
                                            <option value="EP">EP</option>
                                            <option value="Single">Single</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-6 p-2">
                                        <label htmlFor="lang_track" className={`${inputError && formData.lang_track == '0' ? 'text-danger' : 'text-muted'}`}>Язык релиза <small className={`${inputError && formData.lang_track == '0' ? 'text-danger' : 'text-muted'}`}>*</small></label>
                                        <select
                                            className={`form-control text-muted w-100 ${inputError && formData.lang_track == '0' ? 'border-danger' : ''}`}
                                            id="lang_track" name="lang_track" value={formData.lang_track}
                                            onChange={onChangeInput}>
                                            <option value='0'>Выберите язык релиза</option>
                                            <option value="rus">Русский</option>
                                            <option value="eng">Английский</option>
                                            <option value="spn">Испанский</option>
                                            <option value="japan">Японский</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={animationVariants}
                            transition={{ duration: 0.5, delay: 0.1 }}
                            className="card"
                        >
                            <div className="card-body rounded p-4 mt-4">
                                <div className="p-2">
                                    <h6>Персоны релиза</h6>
                                    <p className="text-muted">Укажите людей, участвовавших в релизе</p>
                                    {inputError && formData.artist_track.length < 1 &&
                                        <p className="text-danger">Необходимо указать как минимум 1 основного артиста</p>
                                    }
                                </div>
                                <div className="w-100 d-flex mb-4">
                                    {formData.artist_track.map((artist, index) =>
                                        <div className="artist-block d-flex align-items-center" key={index}>
                                            <b className="mr-2">{artist}</b>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-trash c-pointer" viewBox="0 0 16 16" onClick={() => removeArtist(index)}>
                                                <path
                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                                <path
                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                            </svg>
                                        </div>
                                    ) }
                                </div>
                                <div className="w-100 d-flex mb-4">
                                    {formData.second_artist_track.map((artist, index) =>
                                        <div className="artist-block d-flex align-items-center" key={index}>
                                            <b className="mr-2">{artist} (feat.)</b>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-trash c-pointer" viewBox="0 0 16 16" onClick={() => removeArtistSecond(index)}>
                                                <path
                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                                <path
                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                            </svg>
                                        </div>
                                    ) }
                                </div>
                                <label className="btn mainButton d-flex align-items-center" onClick={openModal} style={{width: "min-content"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor" className="bi bi-plus-lg mr-2" viewBox="0 0 16 16">
                                        <path fillRule="evenodd"
                                              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                                    </svg>
                                    Добавить артиста
                                </label>
                                <div className="modal modal-add-track" style={{ display: showModal ? "block" : "none" }}>
                                    <div className="modal-content">
                                        <HeaderModal title="Добавить артиста" closeModal={closeModal} />
                                        <div className='row text-left p-4 justify-content-center'>
                                            <div className="col-12 col-md-6">
                                                <ArtistSelect closeModal={closeModal} formData={formData} setFormData={setFormData} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={animationVariants}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            className="card"
                        >
                            <div className="card-body rounded p-4 mt-4">
                                <div className="p-2">
                                    <h6>Обложка релиза</h6>
                                    <p className="text-muted">Загрузите обложку релиза</p>
                                </div>
                                <AddCropperAlbum setImage={setImageAlbum} />
                            </div>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={animationVariants}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className="card"
                        >
                            <div className="card-body rounded p-4 mt-4">
                                <div className="p-2">
                                    <h6>Жанр</h6>
                                    <p className={`${inputError && formData.zhanr_track == '0' ? 'text-danger' : 'text-muted'}`}>Выберите жанр релиза</p>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-4 col-md-6 mt-2 p-2">
                                        <select className={`form-control text-muted w-100 ${inputError && formData.zhanr_track == '0' ? 'border-danger' : ''}`} name="zhanr_track" value={formData.zhanr_track} onChange={onChangeInput}>
                                            <option value="0">Выберите жанр релиза</option>
                                            <option value="Рок">Рок</option>
                                            <option value="Рэп">Рэп</option>
                                            <option value="Трэп">Трэп</option>
                                            <option value="RnB">RnB</option>
                                            <option value="Соул">Соул</option>
                                            <option value="Поп_Рэп">Поп Рэп</option>
                                            <option value="Электронная_музыка">Электронная музыка</option>
                                            <option value="Техно">Техно</option>
                                            <option value="Экспериментальная">Экспериментальная</option>
                                            <option value="Drum_and_Bass">Drum & Bass</option>
                                            <option value="House">House</option>
                                            <option value="Другое">Другое</option>
                                            <option value="Акустика">Акустика</option>
                                            <option value="Альтернатива">Альтернатива</option>
                                            <option value="Поп_Рок">Поп Рок</option>
                                            <option value="Панк">Панк</option>
                                            <option value="Инди">Инди</option>
                                            <option value="Метал">Метал</option>
                                            <option value="Поп">Поп</option>
                                            <option value="Классическая">Классическая</option>
                                            <option value="Фолк">Фолк</option>
                                            <option value="Саундтрек">Саундтрек</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={animationVariants}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="card"
                        >
                            <div className="card-body rounded p-4 mt-4">
                                <div className="p-2">
                                    <h6>Даты релиза</h6>
                                    <p className="text-muted">Укажите дату релиза и дату предзаказа.</p>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-4 col-md-6 mt-2 p-2">
                                        <label className={`${inputError && !formData.date_track ? 'text-danger' : 'text-muted'}`} htmlFor="date_track">Дата релиза <small className={`${inputError && !formData.date_track ? 'text-danger' : 'text-muted'}`}>*</small></label>
                                        <input type="date" className={`form-control ${inputError && !formData.date_track ? 'border-danger' : ''}`} placeholder="Дата релиза" id="date_track" name="date_track" value={formData.date_track} onChange={onChangeInput} />
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-6 mt-2 p-2">
                                        <label className={`text-muted`} htmlFor="predate_track">Дата предзаказа</label>
                                        <input type="date" className={`form-control`} placeholder="Дата предзаказа" id="predate_track" name="predate_track" value={formData.predate_track} onChange={onChangeInput} />
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={animationVariants}
                            transition={{ duration: 0.5, delay: 0.5 }}
                            className="card mb-4"
                        >
                            <div className="card-body rounded p-4 mt-4">
                                <div className="p-2">
                                    <h6>Права релиза</h6>
                                    <p className="text-muted">Укажите права релиза</p>
                                </div>
                                <div className="row">
                                    {
                                        (user.subscribe_type === 2 || user.subscribe_type === 3) && currentDate < endDate && currentDate > startDate ? (
                                            <div className="col-12 col-lg-4 col-md-6 mt-2 p-2">
                                                <label
                                                    className="text-muted"
                                                    htmlFor="copyright_track">COPYRIGHT </label>
                                                <input
                                                    className={`form-control`}
                                                    placeholder="COPYRIGHT" id="copyright_track" name="copyright_track"
                                                    value={formData.copyright_track} onChange={onChangeInput}/>
                                            </div>
                                        ) : (
                                            <div className="col-12 col-lg-4 col-md-6 mt-2 p-2">
                                                <label
                                                    className="text-muted"
                                                    htmlFor="copyright_track">COPYRIGHT </label>
                                                <input
                                                    className={`form-control`}
                                                    placeholder="COPYRIGHT" id="copyright_track" name="copyright_track"
                                                    value="muzilok" disabled/>
                                                <label className="mt-1 text-danger">Смена copyright недоступна из-за тарифа</label>
                                            </div>
                                        )
                                    }
                                    <div className="col-12 col-lg-4 col-md-6 mt-2 p-2">
                                        <label className="text-muted" htmlFor="upc_track">UPC </label>
                                        <input className="form-control" placeholder="UPC" id="upc_track"
                                               name="upc_track" value={formData.upc_track} onChange={onChangeInput}/>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        {errMsg &&
                            <Alert variant="outlined" severity="error">
                                {errMsg}
                            </Alert>
                        }
                        {successMsg &&
                            <Alert variant="outlined" severity="success">
                                {successMsg}
                            </Alert>
                        }
                        <button type="submit" disabled={wait} className="btn w-100 mainButton mt-4">
                            Добавить альбом
                        </button>
                    </form>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default AddAlbum;
