import { useContext, useEffect } from 'react';
import { UserContext } from '../../API/UserContext';
import { Link } from 'react-router-dom';
import MyAlbumsList from '../../blocks/MyAlbums';
import Settings from './Settings';

import men from '../../assets/images/men.jpg';

const Profile = () => {
    const { user, logout } = useContext(UserContext);

    useEffect(() => {
        var title = "Профиль @" + user?.username;
        document.title = title;
    }, [user?.username]);

    return (
        <div className='w-100 row m-auto'>
            <div className="col-lg-12 p-3  col-md-12">
                <div className="row">
                    <Settings />
                </div>
            </div>
            <div className="row col-12">
                <div className="col-12 col-md-8 col-lg-10">

                </div>
                <div className="col-12 col-md-4 col-lg-2">
                    {
                        window.Telegram.WebApp.initDataUnsafe.user === undefined && (
                            <Link className='btn btn-danger w-100 mt-2' onClick={logout}>Выйти</Link>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Profile;