import React from 'react';

const Index = () => {
    return (
        <div className="container-fluid">
            <h3>Пользовательское соглашение</h3>

            <h5>Требования к релизу</h5>

            <h6>Требования к аудиофайлу</h6>

            <ol>
                    <li>Трек должен быть формата wav, 44.100 Гц, 16/24 бит, стерео. Вы можете использовать
                            конвертер (например, этот)
                    </li>
                    <li> ! Вы должны обладать 100% авторских и 100% смежных прав на произведение. Нельзя
                            нарушать авторские права, использовать инструменталы без разрешения автора
                            (желательно письменного). Важно: "Free For Profit" биты не соответствуют данному
                            условию и не допускаются к публикации.
                    </li>
                    <li>Для публикации ремикса требуется письменное разрешение автора.</li>
                    <li>Все треки должны иметь приемлемое качество записи, сведения и мастеринга. Сервис
                            вправе отказать в публикации релиза по причине низкого качества композиции,
                            отсутствия ценности, неуважения к профессии музыканта и другим пользователям
                            дистрибьютора.
                    </li>
            </ol>

            <h5>Требования к обложке</h5>

            <ol>
                    <li>Обложка должна быть формата jpg, 3000х3000 пикселей в соответствующем качестве</li>
                    <li>! Обложка должна быть оригинальной, использование чужих изображений без разрешения
                            автора не допускается.
                    </li>
                    <li>Нельзя использовать логотипы и иные зарегистрированные торговые марки, включая
                            изображения площадок. Исключение - логотип музыкального проекта, группы.
                    </li>
                    <li>Нельзя использовать объекты, охраняемые авторским правом. Например: герои
                            мультфильмов, фильмов, компьютерных игр и так далее.
                    </li>
            </ol>

            <p className="text-danger"><strong className="text-danger">! нарушение данных пунктов ведет к расторжению договора и закрытию доступа к
                    аккаунту.</strong></p>

            <h5>Особые требования для Apple Music и iTunes</h5>

            <ol>
                <li>Трек не должен являться кавером, трибьютом или караоке-версией.</li>
                <li>Обложка не должна быть размыта, заблюрена или пикселизирована (даже если это часть
                        авторской задумки). На изображении не должно быть рамок, оно должно занимать
                        весь объем.
                </li>
                <li>На обложке не должно быть какой-либо информации (текстовой), кроме имени исполнителя
                        и названия релиза. При этом, обложка может не содержать текст вовсе.
                </li>
                <li>Логотип "Parental Advisory" можно использовать только, если хотя бы один трек в
                        релизе отмечен как содержащий нецензурную лексику. Необходимо использовать
                        официальный "Parental Advisory", он должен быть четко виден (нельзя использовать
                        эффекты, размытие и так далее).
                </li>
                <li>Жанр должен быть выбран верно. В частности, артисты часто помечают хип-хоп музыку
                        как R&B или "Альтернатива".
                </li>
                <li>Нельзя загружать несколько релизов с одной обложкой. В таком случае на Apple Music &
                        iTunes будет отправлен только первый загруженный релиз.
                </li>
                <li>Минимальная возможная длительности релиза - 01:30 (90 секунд).</li>
                <li>Названия не могут сочетать в себе два алфавита (например, латиницу или кириллицу),
                        они должны быть выдержаны в одном языке.
                </li>
                <li>Запрещается использовать в названиях транслит (написание слова из одного языка
                        алфавитом другого. Например: "Privet"). Слова должны быть написаны на языке, к
                        которому они принадлежат.
                </li>
                <li>Для площадки Apple Music/iTunes особенно важно соблюдение оригинальности обложки и
                        полное наличие прав на неё. Использование чужих изображений исключено.
                </li>
            </ol>

            <p>Полный список правил Apple Music доступен на их <a
                href="https://help.apple.com/itc/musicstyleguide/en.lproj/static.html">сайте</a>.
            </p>

            <h5>Особые требования для Facebook (Instagram) & YouTube Content ID</h5>

            <p>Согласно правилам данных площадок, загружаемый контент должен быть полностью уникальным.
                    Таким образом, чтобы релиз был отправлен на эти сервисы, он не должен содержать:</p>
            <ul>
                <li>Публично доступные отрывки, сэмплы или речи.</li>
                <li>Бесплатные лупы, а также звуковые эффекты (например, звуки животных, природы и так
                        далее).
                </li>
                <li>Любой контент, созданный третьими лицами, на который вы не имеете полноценных
                        прав.
                </li>
            </ul>
            <p>Помимо этого, на данные площадки не допускаются караоке-версии или треки, схожие до смешения
                    с уже загруженными.</p>
            <p><strong className="text-danger">* - Деятельность организации Meta на территории РФ запрещена.</strong></p>
        </div>
    );
};

export default Index;