import React, { useState, useCallback, useContext } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, RadioGroup, Radio, FormControlLabel, LinearProgress, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import { UserContext } from './../API/UserContext';

function TrackSelectionDialog({ open, onClose, tracks, fileName, albumId }) {
    const [selectedTrack, setSelectedTrack] = useState('');

    const handleCancel = () => {
        onClose(null, null);
    };

    const handleOk = () => {
        onClose(selectedTrack, albumId);
    };

    const handleChange = (event) => {
        setSelectedTrack(event.target.value);
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Выберите трек для файла "{fileName}"</DialogTitle>
            <DialogContent>
                <RadioGroup name="track" value={selectedTrack} onChange={handleChange}>
                    {tracks.map((track) => (
                        <FormControlLabel value={track.id.toString()} key={track.id} control={<Radio />} label={track.name} />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Отмена</Button>
                <Button onClick={handleOk} disabled={!selectedTrack}>Загрузить</Button>
            </DialogActions>
        </Dialog>
    );
}

function FileUpload({ tracks, albumId }) {
    const [filesQueue, setFilesQueue] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const { user } = useContext(UserContext);

    const onDrop = useCallback((acceptedFiles) => {
        setFilesQueue(acceptedFiles);
        setCurrentFile(acceptedFiles[0]);
    }, []);

    const handleClose = (trackId, albumId) => {
        console.log(albumId)
        if (trackId) {
            uploadFile(currentFile, trackId, albumId);
        }
        const nextFiles = filesQueue.filter(file => file !== currentFile);
        setFilesQueue(nextFiles);
        setCurrentFile(nextFiles.length > 0 ? nextFiles[0] : null);
    };

    const uploadFile = (file, trackId, albumId) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('trackId', trackId);
        formData.append('albumId', albumId);
        formData.append('username', user.username);

        axios.post('https://api.muzilok.ru/saveFileRule', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percent);
            }
        }).then(response => {
            setUploadedFiles(prev => [...prev, { trackId, fileName: file.name, fileId: response.data.fileId, uploading: false }]);
            setLoading(false);
        }).catch(error => {
            console.error('Error uploading file:', error);
            setLoading(false);
        });
    };

    const handleDelete = (fileId) => {

        const formData = new FormData();
        formData.append('fileId', fileId);
        formData.append('username', user.username);

        axios.post(`https://api.muzilok.ru/deleteFileRule`, formData)
            .then(() => {
                setUploadedFiles(prevFiles => prevFiles.filter(file => file.fileId !== fileId));
            })
            .catch(error => console.error('Error deleting file:', error));
    };

    const loadFilesRule = async () => {
        setLoading(true);

        const formData = new FormData();
        formData.append('albumId', albumId);
        formData.append('username', user.username);

        try {
            const response = await axios.post('https://api.muzilok.ru/getFilesRule', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data && response.data.files) {
                setUploadedFiles(response.data.files.map(file => ({
                    ...file,
                    uploading: false
                })));
            } else {
                console.error('No files data received');
            }
        } catch (error) {
            console.error('Error loading files:', error);
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        loadFilesRule();
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    return (
        <Box sx={{ p: 2 }}>
            <div {...getRootProps()} style={{padding: 16, paddingBottom: 30, paddingTop: 30, border: isDragActive ? "1px dashed #663de0" : "1px dashed gray"}}>
                <input {...getInputProps()} />
                <Typography className="text-center" style={{color: isDragActive ? "#663de0" : "gray"}}>{isDragActive ? 'Отпустить файлы' : 'Перенесите файлы или выберите, нажав мышью (максимальный вес 15 Мб)'}</Typography>
                {loading && <LinearProgress variant="determinate" value={uploadProgress} />}
            </div>
            {currentFile && (
                <TrackSelectionDialog
                    albumId={albumId}
                    open={!!currentFile}
                    onClose={handleClose}
                    tracks={tracks}
                    fileName={currentFile.name}
                />
            )}
            <table className="table mt-4 table-striped table-dark w-100">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Файл</th>
                        <th>Название трека</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {uploadedFiles.map((file, index) => (
                        <tr key={index}>
                            <th>{index + 1}</th>
                            <th>
                                {file.fileName}
                            </th>
                            <th>{tracks.find(track => track.id.toString() === file.trackId)?.name}</th>
                            <th>
                                <IconButton onClick={() => handleDelete(file.fileId)}>
                                    <DeleteIcon />
                                </IconButton>
                            </th>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Box>
    );
}

const SaveFilesRule = ({ tracks, albumId }) => {

    return (
        <FileUpload tracks={tracks} albumId={albumId} />
    );
};

export default SaveFilesRule;