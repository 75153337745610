import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { UserContext } from './API/UserContext';
import TrackContextProvider from './API/TrackContext';

import "./assets/css/bootstrap.min.css";
import "./assets/css/main.css";

import Header from "./blocks/Header";
import AnimatedComponent from './AnimatedComponent';

import Main from './pages/Main';
import Login from './pages/User/Login';
import Register from './pages/User/Register';
import Profile from './pages/User/Profile';
import Settings from './pages/User/Settings';
import AddAlbum from './pages/add/AddAlbum';
import AlbumView from './pages/view/AlbumView';
import ContactView from './pages/view/ContactView';
import LargePlayer from './blocks/LargePlayer';
import EditAlbum from './pages/Edit/EditAlbum';
import SubscribeSettings from './pages/User/SubscribeSettings';
import BandLink from './pages/view/BandLink';
import { LargePlayerContextProvider } from './Context/LargePlayer';
import NetworkStatus from './blocks/NetworkStatus';
import Wallet from './pages/wallet/Page';
import Privacy from './pages/Privacy/Index';
import AlbumPlaysChart from './components/Statistic/AllStat';
import MyAlbumsList from './blocks/MyAlbums';
import PageNotFound from './pages/Privacy/PageNotFound';

const App = () => {
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    const spinner = document.getElementById("spinner");
    if (spinner) {
        setTimeout(() => {
            spinner.style.display = "none";
            setLoading(false);
        }, 1000);
    }

    const TelegramWindow = window.Telegram.WebApp;

    if (typeof(TelegramWindow.initDataUnsafe.user) !== 'undefined') {
        TelegramWindow.expand();
        TelegramWindow.enableClosingConfirmation();
    }

    return (
        !loading && (
            <LargePlayerContextProvider>
                <BrowserRouter>
                    <AnimatedRoutes user={user} />
                </BrowserRouter>
                <LargePlayer />
            </LargePlayerContextProvider>
        )
    );
}

const AnimatedRoutes = ({ user }) => {
    const location = useLocation();

    return (
        <AnimatePresence mode='wait'>
            <TrackContextProvider>
                <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Navigate to={"/login"} />} />
                <Route path="/privacy" element={
                    <Header element={
                        <Privacy />
                    } />
                } />
                {!user ? (
                    <>
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Register />} />
                        <Route path="/profile" element={<Navigate to="/login" />} />
                        <Route path="/settings" element={<Navigate to="/login" />} />
                        <Route path="/add/album" element={<Navigate to="/login" />} />
                        <Route path="/album/:albumId" element={<Navigate to="/login" />} />
                        <Route path="/statistic" element={<Navigate to="/login" />} />
                    </>
                ) : (
                    <>
                        <Route path="/login" element={<Navigate to="/profile" />} />
                        <Route path="/signup" element={<Navigate to="/profile" />} />
                        <Route path="/profile" element={
                            <Header element={
                                <TrackContextProvider>
                                    <AnimatedComponent>
                                        <Profile />
                                    </AnimatedComponent>
                                </TrackContextProvider>
                            } />
                        } />
                        <Route path="/settings" element={
                            <Header element={
                                <AnimatedComponent>
                                    <Settings />
                                </AnimatedComponent>
                            } />
                        } />
                        <Route path="/settings/subscribe" element={
                            <Header element={
                                <AnimatedComponent>
                                    <SubscribeSettings />
                                </AnimatedComponent>
                            } />
                        } />
                        <Route path="/wallet" element={
                            <Header element={
                                <AnimatedComponent>
                                    <Wallet />
                                </AnimatedComponent>
                            } />
                        } />
                        <Route path="/add/album" element={
                            <Header element={
                                <TrackContextProvider>
                                    <AnimatedComponent>
                                        <AddAlbum />
                                    </AnimatedComponent>
                                </TrackContextProvider>
                            } />
                        } />
                        <Route path="/album/:albumId" element={
                            <Header element={
                                <TrackContextProvider>
                                    <AnimatedComponent>
                                        <AlbumView />
                                    </AnimatedComponent>
                                </TrackContextProvider>
                            } />
                        } />
                        <Route path="/edit/album/:albumId" element={
                            <Header element={
                                <TrackContextProvider>
                                    <AnimatedComponent>
                                        <EditAlbum />
                                    </AnimatedComponent>
                                </TrackContextProvider>
                            } />
                        } />
                        <Route path="/statistic" element={
                            <Header element={
                                <AnimatedComponent>
                                    <AlbumPlaysChart />
                                </AnimatedComponent>
                            } />
                        } />
                        <Route path="/albums" element={
                            <Header element={
                                <TrackContextProvider>
                                    <AnimatedComponent>
                                        <MyAlbumsList />
                                    </AnimatedComponent>
                                </TrackContextProvider>
                            } />
                        } />
                    </>
                )}
                <Route path="/contacts" element={
                    <Header element={
                        <AnimatedComponent>
                            <ContactView />
                        </AnimatedComponent>
                    } />
                } />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
            </TrackContextProvider>
        </AnimatePresence>
    );
}

export default App;