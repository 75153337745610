import React from 'react';
import heartBroken from './../../assets/gif/heart-broken.gif';

const PageNotFound = () => {
    return (
        <div className={"w-100 d-flex justify-content-center mt-4 p-4"}>
            <div className="p-4 text-center">
                <h2>Страница не найдена!</h2>
                <h3>404</h3>
                <img src={heartBroken} width={"100px"} height={"100px"}/>
                <br/>
                <a href={"/login"}>Назад</a>
                <br/>
            </div>
        </div>
    );
};

export default PageNotFound;