import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, List, ListItem, ListItemText, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SvgIcon from '@mui/material/SvgIcon';

function getToken() {
    const token = localStorage.getItem('_token');

    if (!token) {
        return sessionStorage.getItem('_token');
    }
    return token;
}

const ControlArtists = () => {
    const [artists, setArtists] = useState([]);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [newArtist, setNewArtist] = useState({ vk_link: '', spotify_link: '', username: '' });
    const [currentArtist, setCurrentArtist] = useState({ id: '', vk_link: '', spotify_link: '', username: '' });
    const [error, setError] = useState('');
    const _token = getToken();

    useEffect(() => {
        fetchArtists();
    }, []);

    const fetchArtists = async () => {
        try {
            const response = await axios.get(`https://api.muzilok.ru/getArtists?_token=${_token}`);
            setArtists(response.data);
        } catch (error) {
            console.error('Error fetching artists:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.get(`https://api.muzilok.ru/deleteArtist?_token=${_token}&id=${id}`);
            fetchArtists();
        } catch (error) {
            console.error('Error deleting artist:', error);
        }
    };

    const handleAddArtist = async () => {
        try {
            const response = await axios.get(`https://api.muzilok.ru/addArtist?_token=${_token}&vk_link=${newArtist.vk_link}&spotify_link=${newArtist.spotify_link}&username=${newArtist.username}`);
            if (response.data.message === 'Артист добавлен') {
                fetchArtists();
                handleClose();
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            console.error('Error adding artist:', error);
            setError('Ошибка при добавлении артиста');
        }
    };

    const handleEditArtist = async () => {
        try {
            const response = await axios.get(`https://api.muzilok.ru/updateArtist?_token=${_token}&id=${currentArtist.id}&vk_link=${currentArtist.vk_link}&spotify_link=${currentArtist.spotify_link}&username=${currentArtist.username}`);
            if (response.data.message === 'Артист обновлен') {
                fetchArtists();
                handleEditClose();
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            console.error('Error editing artist:', error);
            setError('Ошибка при редактировании артиста');
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNewArtist({ vk_link: '', spotify_link: '', username: '' });
        setError('');
    };

    const handleEditClickOpen = (artist) => {
        setCurrentArtist(artist);
        setEditOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
        setCurrentArtist({ id: '', vk_link: '', spotify_link: '', username: '' });
        setError('');
    };

    return (
        <div>
            <h5>Мои артисты:</h5>
            <List>
                {artists.map(artist => (
                    <ListItem key={artist.id} className={"card-body rounded mt-2"}>
                        <ListItemText primary={artist.username} />
                        <div>
                            <IconButton>
                                <SvgIcon>
                                    <svg style={{width: 20, height: 20}} onClick={() => handleEditClickOpen(artist)} xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                                         fill="currentColor"
                                         className="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path
                                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd"
                                              d="M1 13.5A1.5.5 0 0 0 2.5 15h11a1.5.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5.5 0 0 0 1 2.5z"/>
                                    </svg>
                                </SvgIcon>
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(artist.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </ListItem>
                ))}
            </List>
            <Button variant="contained" className={"btn mainButton"} startIcon={<AddIcon />} onClick={handleClickOpen}>
                Добавить артиста
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Добавить артиста</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Пожалуйста, введите ссылку на ВКонтакте и Spotify артиста, а также никнейм.
                    </DialogContentText>
                    <label className={"mt-3"}>Ссылка на VK</label>
                    <input
                        autoFocus
                        margin="dense"
                        label="ВКонтакте"
                        type="text"
                        fullWidth
                        className="form-control mt-2"
                        value={newArtist.vk_link}
                        onChange={(e) => setNewArtist({...newArtist, vk_link: e.target.value})}
                    />
                    <label className={"mt-3"}>Ссылка на Spotify</label>
                    <input
                        margin="dense"
                        label="Spotify"
                        type="text"
                        fullWidth
                        className="form-control mt-2"
                        value={newArtist.spotify_link}
                        onChange={(e) => setNewArtist({...newArtist, spotify_link: e.target.value})}
                    />
                    <label className={"mt-3"}>Имя артиста</label>
                    <input
                        margin="dense"
                        label="Никнейм"
                        type="text"
                        fullWidth
                        className="form-control mt-2"
                        value={newArtist.username}
                        onChange={(e) => setNewArtist({...newArtist, username: e.target.value})}
                    />
                    {error && <p className="text-danger">{error}</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleAddArtist} color="primary">
                        Добавить
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editOpen} onClose={handleEditClose}>
                <DialogTitle>Редактировать артиста</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Пожалуйста, обновите ссылку на ВКонтакте и Spotify артиста, а также никнейм.
                    </DialogContentText>
                    <label className={"mt-3"}>Ссылка на VK</label>
                    <input
                        autoFocus
                        margin="dense"
                        label="ВКонтакте"
                        type="text"
                        fullWidth
                        className="form-control mt-2"
                        value={currentArtist.vk_link}
                        onChange={(e) => setCurrentArtist({...currentArtist, vk_link: e.target.value})}
                    />
                    <label className={"mt-3"}>Ссылка на Spotify</label>
                    <input
                        margin="dense"
                        label="Spotify"
                        type="text"
                        fullWidth
                        className="form-control mt-2"
                        value={currentArtist.spotify_link}
                        onChange={(e) => setCurrentArtist({...currentArtist, spotify_link: e.target.value})}
                    />
                    <label className={"mt-3"}>Имя артиста</label>
                    <input
                        margin="dense"
                        label="Никнейм"
                        type="text"
                        fullWidth
                        className="form-control mt-2"
                        value={currentArtist.username}
                        onChange={(e) => setCurrentArtist({...currentArtist, username: e.target.value})}
                    />
                    {error && <p className="text-danger">{error}</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleEditArtist} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ControlArtists;