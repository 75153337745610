import React, { useContext, useEffect, useRef, useState } from 'react';

import { UserContext } from '../../API/UserContext';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import moneyFlyingGif from './../../assets/gif/money-flying.gif';

const Wallet = () => {

    const [open, setOpen] = React.useState(false);
    const [openChange, setOpenChange] = React.useState(false);
    const [copy, setCopy] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseChange = () => {
        setOpenChange(false);
    };

    const { user } = useContext(UserContext);

    const handleCopyToken = () => {
        navigator.clipboard.writeText(user.wallet_token)
            .then(() => setCopy(true))
            .catch(err => console.error('Ошибка при копировании:', err));
    };

    const [operations, setOperations] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('_token') ? localStorage.getItem('_token') : sessionStorage.getItem('_token');
        const url = `https://api.muzilok.ru/getOperations?_token=${token}`;

        const fetchOperations = async () => {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setOperations(data);
            } catch (error) {
                console.error('Ошибка при получении операций:', error);
            }
        };

        fetchOperations();
    }, []);

    return (
        <div className="w-100 p-2">
            <h3>Кошелек</h3>
            <div className="row">
                <div className="col-12 col-xl-3 p-2">
                    <div className="card">
                        <div className="card-body rounded">
                            <div className="d-flex justify-content-between w-100">
                                <h3>{user.balance} ₽</h3>
                                <img src={moneyFlyingGif} width={"50px"} height={"50px"}/>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <Button variant="text" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: "15px" }}>
                                        <div className="mainButton rounded-circle d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
                                            </svg>
                                        </div>
                                        <b className="mt-2">Пополнить</b>
                                    </Button>
                                </div>
                                <div className="col-4">
                                    <Button variant="text" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: "15px" }} onClick={handleClickOpen}>
                                        <div className="mainButton rounded-circle d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
                                                <path fillRule="evenodd"
                                                      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
                                            </svg>
                                        </div>
                                        <b className="mt-2">Вывести</b>
                                    </Button>
                                </div>
                                <div className="col-4">
                                    <Button onClick={() => setOpenChange(true)} variant="text" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: "15px" }}>
                                        <div className="mainButton rounded-circle d-flex align-items-center justify-content-center" style={{ width: 30, height: 30 }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-arrow-down-up"
                                                 viewBox="0 0 16 16">
                                                <path fillRule="evenodd"
                                                      d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"/>
                                            </svg>
                                        </div>
                                        <b className="mt-2">Обменять</b>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-9 p-2">
                    <div className="card">
                        <div className="card-body rounded">
                            <h3>История операция</h3>
                            {
                                operations.length > 0 ? (
                                    <div className="row">
                                        {
                                            operations.map(operation => (
                                                <div key={operation.id} className={`col-12 border alert border-${operation.type === 'plus' ? ('success') : ('danger')} mt-2 pl-3 pr-3 pt-2 pb-2 d-flex justify-content-between align-items-center`}>
                                                    <b className={operation.type === 'plus' ? 'text-success' : 'text-danger'}>{operation.description}</b>
                                                    <div>
                                                        {operation.type === 'plus' ? (
                                                            <b className="text-success d-flex align-items-center">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                                                     height="20" fill="var(--success)"
                                                                     className="bi bi-plus" viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                                                </svg>
                                                                {operation.summa}
                                                            </b>
                                                        ) : (
                                                            <b className="text-danger d-flex align-items-center">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                                                     height="20" fill="var(--danger)"
                                                                     className="bi bi-dash"
                                                                     viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/>
                                                                </svg>
                                                                {operation.summa}
                                                            </b>
                                                        )}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <p className="text-center">Вы не совершали ни одной операции</p>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Вывод средств"}
                    </DialogTitle>
                    <DialogContent>

                        <div className="p-2 d-flex align-items-center justify-content-between">
                            {/*<b style={{fontSize: 14}}>{user.wallet_token}</b>*/}
                            {/*<Button onClick={handleCopyToken} variant="text" color="primary">*/}
                            {/*    {*/}
                            {/*        copy ? (*/}
                            {/*            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"*/}
                            {/*                 fill="currentColor" className="bi bi-clipboard2-check"*/}
                            {/*                 viewBox="0 0 16 16">*/}
                            {/*                <path*/}
                            {/*                    d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z"/>*/}
                            {/*                <path*/}
                            {/*                    d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z"/>*/}
                            {/*                <path*/}
                            {/*                    d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>*/}
                            {/*            </svg>*/}
                            {/*        ) : (*/}
                            {/*            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                            {/*                 className="bi bi-clipboard2" viewBox="0 0 16 16">*/}
                            {/*                <path*/}
                            {/*                    d="M3.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H12a.5.5 0 0 1 0-1h.5A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1H4a.5.5 0 0 1 0 1z"/>*/}
                            {/*                <path*/}
                            {/*                    d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5"/>*/}
                            {/*            </svg>*/}
                            {/*        )*/}
                            {/*    }*/}
                            {/*</Button>*/}
                            <p>За выплатами обращаться в <a href="https://t.me/muzilokdayhit">поддержку</a></p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Закрыть</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    open={openChange}
                    onClose={handleCloseChange}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Обменять накопления"}
                    </DialogTitle>
                    <DialogContent>

                        <div className="p-2 d-flex align-items-center justify-content-between">
                            {/*<Button onClick={handleCopyToken} variant="text" color="primary">*/}
                            {/*    {*/}
                            {/*        copy ? (*/}
                            {/*            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"*/}
                            {/*                 fill="currentColor" className="bi bi-clipboard2-check"*/}
                            {/*                 viewBox="0 0 16 16">*/}
                            {/*                <path*/}
                            {/*                    d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z"/>*/}
                            {/*                <path*/}
                            {/*                    d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z"/>*/}
                            {/*                <path*/}
                            {/*                    d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>*/}
                            {/*            </svg>*/}
                            {/*        ) : (*/}
                            {/*            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                            {/*                 className="bi bi-clipboard2" viewBox="0 0 16 16">*/}
                            {/*                <path*/}
                            {/*                    d="M3.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H12a.5.5 0 0 1 0-1h.5A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1H4a.5.5 0 0 1 0 1z"/>*/}
                            {/*                <path*/}
                            {/*                    d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5"/>*/}
                            {/*            </svg>*/}
                            {/*        )*/}
                            {/*    }*/}
                            {/*</Button>*/}
                            <p>В разработке</p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseChange}>Закрыть</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    );
};

export default Wallet;