import React from 'react';
import { Link } from "react-router-dom";
import logo from '../assets/images/logo-orange.png';
import men from '../assets/images/men.jpg';
import { UserContext } from '../API/UserContext';
import { useContext } from 'react';
import { usePlayer } from './../Context/LargePlayer';

import SearchInput from './../components/Inputs/SearchInput';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

function TemporaryDrawer({ open, setOpen}) {

  const { user, logout } = useContext(UserContext);
  const { trackUrl } = usePlayer();

  const currentPath = window.location.pathname;

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
      <>
        <div className="menu__content">
          <Link to="/">
            <img src={logo} alt="logo" className="menu__logo" />
          </Link>

          <div className="menu__buttons">
            {user ? (
                <>
                  <Button onClick={() => setOpen(false)} className={currentPath === '/profile' ? ('menu__button menu__button__active') : ("menu__button")}>
                      <Link to="/profile" className={"d-flex align-items-center justify-content-center"}>
                          <div className={"w-75 w-md-50 d-flex align-items-center"}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#e8804c"
                                   className="bi bi-person-fill" viewBox="0 0 16 16">
                                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                              </svg>
                              <b className="textMenu" style={{marginLeft: 7, fontSize: 18}}>Профиль</b>
                          </div>
                      </Link>
                  </Button>
                    <Button onClick={() => setOpen(false)} className={currentPath === '/statistic' ? ('menu__button menu__button__active') : ("menu__button")}>
                        <Link to="/statistic" className={"d-flex align-items-center justify-content-center"}>
                            <div className={"w-75 w-md-50 d-flex align-items-center"}>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{minWidth: 25}} width="30" height="30" fill="#e8804c"
                                     className="bi bi-bar-chart iconMenu" viewBox="0 0 16 16">
                                    <path
                                        d="M4 11H2v3h2v-3zm5-4H7v7h2V7zm5-5v12h-2V2h2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3z"/>
                                </svg>
                                <b className="textMenu" style={{marginLeft: 7, fontSize: 18}}>Статистика</b>
                            </div>
                        </Link>
                    </Button>
                    <Button onClick={() => setOpen(false)} className={currentPath === '/add/album' ? ('menu__button menu__button__active') : ("menu__button")}>
                        <Link to="/add/album" className={"d-flex align-items-center justify-content-center"}>
                            <div className={"w-75 w-md-50 d-flex align-items-center"}>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{minWidth: 25}} width="30" height="30" fill="#e8804c"
                                     className="bi bi-plus-square iconMenu" viewBox="0 0 16 16">
                                    <path
                                        d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                    <path
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                </svg>
                                <b className="textMenu" style={{marginLeft: 7, fontSize: 18}}>Создать релиз</b>
                            </div>
                        </Link>
                    </Button>
                    <Button onClick={() => setOpen(false)} className={currentPath === '/albums' ? ('menu__button menu__button__active') : ("menu__button")}>
                        <Link to="/albums" className={"d-flex align-items-center justify-content-center"}>
                            <div className={"w-75 w-md-50 d-flex align-items-center"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" style={{minWidth: 25}} height="30" fill="#e8804c"
                                     className="bi bi-file-earmark-music iconMenu" viewBox="0 0 16 16">
                                    <path
                                        d="M11 6.64a1 1 0 0 0-1.243-.97l-1 .25A1 1 0 0 0 8 6.89v4.306A2.6 2.6 0 0 0 7 11c-.5 0-.974.134-1.338.377-.36.24-.662.628-.662 1.123s.301.883.662 1.123c.364.243.839.377 1.338.377s.974-.134 1.338-.377c.36-.24.662-.628.662-1.123V8.89l2-.5z"/>
                                    <path
                                        d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                                </svg>
                                <b className="textMenu" style={{marginLeft: 7, fontSize: 18}}>Релизы</b>
                            </div>
                        </Link>
                    </Button>
                    <Button onClick={() => setOpen(false)}
                        className={currentPath === '/wallet' ? ('menu__button menu__button__active') : ("menu__button")}>
                        <Link to="/wallet" className={"d-flex align-items-center justify-content-center"}>
                            <div className={"w-75 w-md-50 d-flex align-items-center"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" style={{minWidth: 25}} height="30" fill="#e8804c"
                                     className="bi bi-cash-stack iconMenu" viewBox="0 0 16 16">
                                    <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                                    <path
                                        d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2z"/>
                                </svg>
                                <b className="textMenu" style={{marginLeft: 7, fontSize: 18}}>Кошелёк</b>
                            </div>
                        </Link>
                    </Button>
                  {user ? (
                      <Button onClick={() => setOpen(false)}
                          className={currentPath === '/logout' ? ('menu__button menu__button__active') : ("menu__button")} onClick={logout}>
                          <Link className={"d-flex align-items-center justify-content-center"}>
                              <div className={"w-75 w-md-50 d-flex align-items-center"}>
                                  <svg xmlns="http://www.w3.org/2000/svg"  width="30" style={{minWidth: 25}} height="30" fill="#e8804c"
                                       className="bi bi-box-arrow-left" viewBox="0 0 16 16">
                                      <path fillRule="evenodd"
                                            d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
                                      <path fillRule="evenodd"
                                            d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
                                  </svg>
                                  <b className="textMenu" style={{marginLeft: 7, fontSize: 18}}>Выйти</b>
                              </div>
                          </Link>
                      </Button>
                      ) : (<></>)}
                </>
            ) : (<> </>)}
          </div>
        </div>
      </>
  );

    return (
        <div>
            <Drawer sx={{background: "#222222"}} open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}

const Header = (props) => {

    const {user, logout} = useContext(UserContext);
    const {trackUrl} = usePlayer();

    const currentPath = window.location.pathname;

    const [openDrawer, setOpenDrawer] = React.useState(false);

    const [hideMenu, setHideMenu] = React.useState(false);

    return (
      <>
          <div className="d-flex w-100">
            <div className="d-none d-lg-block col-lg-2">
              <div id="menu" style={hideMenu ? {width: 150} : {}}>
                <div className="menu__content">
                    <Link to="/" className={"d-flex align-items-center justify-content-center"}>
                        <img src={logo} alt="logo" className="menu__logo"/>
                        <h3 id="pageName" className="concert-one-regular text-uppercase textMenu mt-2" style={hideMenu ? {display: 'none'} : {}}>muzilok</h3>
                    </Link>

                    <div className="menu__buttons">
                        {user ? (
                            <>
                        <Button className={currentPath === '/statistic' ? ('menu__button menu__button__active') : ("menu__button")}>
                            <Link to="/statistic" className={"d-flex align-items-center justify-content-center"}>
                                <div className={"w-75 w-md-50 d-flex align-items-center"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" style={hideMenu ? { marginLeft: 30, minWidth: 25 } : { minWidth: 25 }} width="25" height="25" fill="#e8804c"
                                         className="bi bi-bar-chart iconMenu" viewBox="0 0 16 16">
                                        <path
                                            d="M4 11H2v3h2v-3zm5-4H7v7h2V7zm5-5v12h-2V2h2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3z"/>
                                    </svg>
                                    <b className="textMenu" style={hideMenu ? { display: 'none', marginLeft: 7, fontSize: 18 } : { marginLeft: 7, fontSize: 18 }}>Статистика</b>
                                </div>
                            </Link>
                        </Button>
                          <Button className={currentPath === '/add/album' ? ('menu__button menu__button__active') : ("menu__button")}>
                            <Link to="/add/album" className={"d-flex align-items-center justify-content-center"}>
                                <div className={"w-75 w-md-50 d-flex align-items-center"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" style={hideMenu ? { marginLeft: 30, minWidth: 25 } : { minWidth: 25 }} width="25" height="25" fill="#e8804c"
                                         className="bi bi-plus-square iconMenu" viewBox="0 0 16 16">
                                        <path
                                            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                        <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                    <b className="textMenu" style={hideMenu ? { display: 'none', marginLeft: 7, fontSize: 18, whiteSpace: "nowrap" } : { marginLeft: 7, fontSize: 18, whiteSpace: "nowrap" }}>Создать релиз</b>
                                </div>
                            </Link>
                        </Button>
                          <Button className={currentPath === '/albums' ? ('menu__button menu__button__active') : ("menu__button")}>
                            <Link to="/albums" className={"d-flex align-items-center justify-content-center"}>
                                <div className={"w-75 w-md-50 d-flex align-items-center"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" style={hideMenu ? { marginLeft: 30, minWidth: 25 } : { minWidth: 25 }} height="25" fill="#e8804c"
                                         className="bi bi-file-earmark-music iconMenu" viewBox="0 0 16 16">
                                        <path
                                            d="M11 6.64a1 1 0 0 0-1.243-.97l-1 .25A1 1 0 0 0 8 6.89v4.306A2.6 2.6 0 0 0 7 11c-.5 0-.974.134-1.338.377-.36.24-.662.628-.662 1.123s.301.883.662 1.123c.364.243.839.377 1.338.377s.974-.134 1.338-.377c.36-.24.662-.628.662-1.123V8.89l2-.5z"/>
                                        <path
                                            d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                                    </svg>
                                    <b className="textMenu" style={hideMenu ? { display: 'none', marginLeft: 7, fontSize: 18 } : { marginLeft: 7, fontSize: 18 }}>Релизы</b>
                                </div>
                            </Link>
                        </Button>
                          <Button
                              className={currentPath === '/wallet' ? ('menu__button menu__button__active') : ("menu__button")}>
                              <Link to="/wallet" className={"d-flex align-items-center justify-content-center"}>
                                  <div className={"w-75 w-md-50 d-flex align-items-center"}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="25" style={hideMenu ? { marginLeft: 30, minWidth: 25 } : { minWidth: 25 }} height="25" fill="#e8804c"
                                           className="bi bi-cash-stack iconMenu" viewBox="0 0 16 16">
                                          <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                                          <path
                                              d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2z"/>
                                      </svg>
                                      <b className="textMenu" style={hideMenu ? { display: 'none', marginLeft: 7, fontSize: 18 } : { marginLeft: 7, fontSize: 18 }}>Кошелёк</b>
                                  </div>
                              </Link>
                          </Button>
                          {/*<button className="menu__button">*/}
                          {/*  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#e8804c" className="bi bi-file-music" viewBox="0 0 16 16">*/}
                          {/*    <path d="M10.304 3.13a1 1 0 0 1 1.196.98v1.8l-2.5.5v5.09c0 .495-.301.883-.662 1.123C7.974 12.866 7.499 13 7 13c-.5 0-.974-.134-1.338-.377-.36-.24-.662-.628-.662-1.123s.301-.883.662-1.123C6.026 10.134 6.501 10 7 10c.356 0 .7.068 1 .196V4.41a1 1 0 0 1 .804-.98l1.5-.3z" />*/}
                          {/*    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />*/}
                        {/*  </svg>*/}
                        {/*</button>*/}
                      </>
                    ) : ( <> </>)}
                  </div>
                </div>
              </div>
            </div>
            <div className={!trackUrl ? "col-md-12 col-lg-10 mobile__hide__height" : "col-md-12 col-lg-10 mobile__hide__height"}>
              <div id="contentBlock">
                <div className="wrapper">
                  <div className="header row">
                      <div className="col-md-6 d-none d-lg-block">
                          {/*<Button onClick={() => setHideMenu(!hideMenu)}>*/}
                          {/*    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#e8804c"*/}
                          {/*         className="bi bi-list" viewBox="0 0 16 16">*/}
                          {/*        <path fill-rule="evenodd"*/}
                          {/*              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>*/}
                          {/*    </svg>*/}
                          {/*</Button>*/}
                          <SearchInput />
                      </div>
                      <div
                          className="col-md-6 col-sm-12 align-items-center d-none d-lg-flex justify-content-lg-end header__col">
                          {!user ? (
                              <Link to="/login" className="btn btn-warning">Войти</Link>
                          ) : (
                              <>
                                  {/*<button className="header__item">*/}
                                  {/*  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" className="bi bi-bell" viewBox="0 0 16 16">*/}
                          {/*    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />*/}
                          {/*  </svg>*/}
                          {/*</button>*/}
                          <Link to="/profile" className="header__miniprofile ml-4 d-flex align-items-center">
                            <img src={men} alt="logo" />
                            {user?.username}
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                  <div id="content">
                    {props.element}
                    <hr className="mt-4 mb-2" />
                    <div className="row mb-4 mt-4">
                        <div className="col-12 col-md-3">
                            <Link to="/">
                                <img src={logo} alt="logo" className="menu__logo"/>
                            </Link>
                            <br/>
                            <small className={"text-muted"}>"Muzilok" - сервис для музыкальной дистрибуции, который помогает музыкантам и лейблам
                                публиковать свои треки в стриминговых сервисах.</small>
                        </div>
                        {user ? (
                            <>
                                <div className="col-4 col-md-4">
                                <h5>Релизы</h5>
                                <Link to="/albums" className={"text-secondary"}>Мои релизы</Link><br/>
                                <Link to="/add/album" className={"text-secondary"}>Создать релиз</Link>
                            </div>
                            <div className="col-4 col-md-4">
                            <h5>Профиль</h5>
                            <Link to='/settings' className={"text-secondary"}>Настройки аккаунта</Link><br />
                            <Link to="/settings/subscribe" className={"text-secondary"}>Настройки подписки</Link>
                          </div>
                        </>
                      ) : (
                        <div className="col-4 col-md-4">
                          <h5>Профиль</h5>
                          <Link to='/login' className={"text-secondary"}>Войти</Link>
                        </div>
                      )}
                    </div>
                    <div className={"row"}>
                        <div className="col-12 col-md-6">
                            &copy; <a href="https://muzilok.ru">MUZILOK</a> 2023 - {new Date().getFullYear()}. <br />created by <a href="https://t.me/PersonalExclusiveProjects">PEProjects</a>. produced by <a href='https://t.me/vmeste_me_sila712'>vealok</a>.
                        </div>
                        <div className="col-12 col-md-6 d-md-flex d-block justify-content-end">
                            <a href={"https://t.me/muziloknews"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#e8804c"
                                     className="bi bi-telegram" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                      <br/>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="mobile__open__menu">
              <Button className="btn mainButton" onClick={() => setOpenDrawer(true)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       className="bi bi-list"
                       viewBox="0 0 16 16">
                      <path fillRule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                  </svg>
              </Button>
          </div>
          <TemporaryDrawer open={openDrawer} setOpen={setOpenDrawer}/>
    </>
    );
}

export default Header;