import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../API/UserContext';
import { Button, CircularProgress, Switch, Alert } from '@mui/material';

const Register = () => {
    const { registerUser, wait, loggedInCheck } = useContext(UserContext);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        passwordRepeat: '',
        rememberMe: false,
        confirmRules: false,
    });
    const [telegramUserId, setTelegramUserId] = useState(null);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (window.Telegram?.WebApp?.initDataUnsafe) {
            const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
            if (userId) {
                setTelegramUserId(userId);
            } else {
                window.location.href = '/login';
            }
        }
    }, []);

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSwitchChange = (e) => {
        setFormData({
            ...formData,
            rememberMe: e.target.checked
        });
    };

    const handleSwitchConfirmRulesChange = (e) => {
        setFormData({
            ...formData,
            confirmRules: e.target.checked
        });
    };

    const submitForm = async (e) => {
        e.preventDefault();

        if (!Object.values(formData).every((val) => val !== '')) {
            setErrMsg('Заполните все поля!');
            return;
        }

        if (formData.password !== formData.passwordRepeat) {
            setErrMsg('Пароли не совпадают!');
            return;
        }

        // Включаем userId от Telegram, если он существует
        const registrationData = {
            ...formData,
            telegramUserId: telegramUserId ? telegramUserId : null
        };

        const data = await registerUser(registrationData);
        if (data.success) {
            e.target.reset();
            setSuccessMsg('Регистрация прошла успешно!');
            setErrMsg(false);
            await loggedInCheck();
            window.location.href = '/login';
            return;
        } else {
            setErrMsg(data.message || 'Ошибка регистрации');
        }
    };

    return (
        <>
            <div className="w-100 text-center p-3 mt-4">
                <h3 id="pageName" className="concert-one-regular text-uppercase">muzilok | регистрация</h3>
            </div>
            <div className="login-page">
                <div className="form note-background">
                    <form className="login-form" onSubmit={submitForm} autoComplete="off">
                        <h5>Зарегистрируйтесь</h5>
                        <input type="text" name="username" className='mt-3 form-input' onChange={onChangeInput} placeholder="Логин"
                               id="username" value={formData.username} required />
                        <input type="password" name="password" className='form-input' onChange={onChangeInput} placeholder="Придумайте пароль"
                               id="password" value={formData.password} required />
                        <input type="password" name="passwordRepeat" className='form-input' onChange={onChangeInput} placeholder="Повторите пароль"
                               id="passwordRepeat" value={formData.passwordRepeat} required />
                        <small className="mb-4 text-left mt-4">
                            <Switch defaultChecked={formData.rememberMe}
                                    onChange={handleSwitchChange} /> Запомнить меня
                        </small>
                        <small className="mb-4 text-left mt-4">
                            <Switch defaultChecked={formData.confirmRules}
                                    onChange={handleSwitchConfirmRulesChange} /> Соглашаюсь с <Link to="/privacy">правилами</Link>
                        </small>
                        {errMsg &&
                            <Alert variant="outlined" severity="error">
                                {errMsg}
                            </Alert>
                        }
                        {successMsg &&
                            <Alert variant="outlined" severity="success">
                                {successMsg}
                            </Alert>
                        }
                        {redirect ?
                            <div className="w-100 p-4 d-flex justify-content-center">
                                <CircularProgress />
                            </div>
                            : null
                        }

                        <Button type="submit" className='btn mainButton mt-2' disabled={wait}>
                            {wait ? <CircularProgress /> : 'Зарегистрироваться'}
                        </Button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Register;
