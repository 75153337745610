import React, { useState, useEffect, useRef } from 'react';
import { usePlayer } from './../Context/LargePlayer';
import { UserContext } from '../API/UserContext';
import { useContext } from 'react';

const LargePlayer = () => {
    const { trackUrl, setTrackUrl, playTrack, isPlaying, togglePlay, title, artist, imgName, trackList, albumId } = usePlayer();
    const audioRef = useRef(null);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const { user, logout } = useContext(UserContext);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({
        x: localStorage.getItem('playerPositionX123') ? parseFloat(localStorage.getItem('playerPositionX123')) : 100,
        y: localStorage.getItem('playerPositionY123') ? parseFloat(localStorage.getItem('playerPositionY123')) : 0,
    });
    const [isCollapsed, setIsCollapsed] = useState(localStorage.getItem('playerCollapsed') === 'true');
    const [localTrackList, setLocalTrackList] = useState(() => {
        return trackList;
    });

    React.useEffect(() => {
        setLocalTrackList(trackList);
    }, [trackList])

    const trackListRef = useRef(localTrackList);

    useEffect(() => {
        trackListRef.current = localTrackList;
    }, [localTrackList]);

    useEffect(() => {
        if (trackUrl) {
            setIsVisible(true); // Показать компонент при наличии trackUrl
            if (isPlaying) {
                audioRef.current.play();
            } else {
                audioRef.current.pause();
            }
        } else {
            setIsVisible(false); // Скрыть компонент при отсутствии trackUrl
        }
    }, [isPlaying, trackUrl]);

    useEffect(() => {
        const audio = audioRef.current;
        if (audio) {
            audio.addEventListener('ended', handleTrackEnded);
            return () => {
                if (audioRef.current) {
                    audioRef.current.removeEventListener('ended', handleTrackEnded);
                }
            };
        }
    }, [audioRef.current, currentTrackIndex]);

    useEffect(() => {
        const savedTrackUrl = localStorage.getItem('trackUrl');
        const savedIsPlaying = localStorage.getItem('isPlaying') === 'true';
        const savedTitle = localStorage.getItem('title');
        const savedArtist = localStorage.getItem('artist');
        const savedImgName = localStorage.getItem('imgName');
        const savedTrackIndex = localStorage.getItem('currentTrackIndex');

        if (savedTrackUrl && savedTitle && savedArtist && savedImgName && savedTrackIndex !== null) {
            setTrackUrl(savedTrackUrl);
            setCurrentTrackIndex(parseInt(savedTrackIndex, 10));
            if (savedIsPlaying) {
                playTrack(savedTrackUrl, savedTitle, savedArtist, savedImgName);
            } else {
                setIsVisible(true);
            }
        }
    }, []);

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleStop = () => {
        setTrackUrl(null);
        localStorage.removeItem('trackUrl');
        localStorage.removeItem('isPlaying');
        localStorage.removeItem('title');
        localStorage.removeItem('artist');
        localStorage.removeItem('imgName');
        localStorage.removeItem('currentTrackIndex');
    };

    const handleTrackEnded = () => {
        const nextTrackIndex = currentTrackIndex < trackListRef.current.length - 1 ? currentTrackIndex + 1 : 0;
        setCurrentTrackIndex(nextTrackIndex);
        const nextTrack = trackListRef.current[nextTrackIndex];
        playTrack(nextTrack.url, nextTrack.title, nextTrack.artist, nextTrack.imgName);
        localStorage.setItem('trackUrl', nextTrack.url);
        localStorage.setItem('title', nextTrack.title);
        localStorage.setItem('artist', nextTrack.artist);
        localStorage.setItem('imgName', nextTrack.imgName);
        localStorage.setItem('currentTrackIndex', nextTrackIndex);
    };

    const handlePreviousTrack = () => {
        let prevTrackIndex;
        if (currentTrackIndex > 0) {
            prevTrackIndex = currentTrackIndex - 1;
        } else {
            prevTrackIndex = localTrackList.length - 1;
        }
        setCurrentTrackIndex(prevTrackIndex);
        const prevTrack = localTrackList[prevTrackIndex];
        playTrack(prevTrack.url, prevTrack.title, prevTrack.artist, prevTrack.imgName);
        localStorage.setItem('trackUrl', prevTrack.url);
        localStorage.setItem('title', prevTrack.title);
        localStorage.setItem('artist', prevTrack.artist);
        localStorage.setItem('imgName', prevTrack.imgName);
        localStorage.setItem('currentTrackIndex', prevTrackIndex);
    };

    const handleNextTrack = () => {
        const nextTrackIndex = currentTrackIndex < localTrackList.length - 1 ? currentTrackIndex + 1 : 0;
        setCurrentTrackIndex(nextTrackIndex);
        const nextTrack = localTrackList[nextTrackIndex];
        playTrack(nextTrack.url, nextTrack.title, nextTrack.artist, nextTrack.imgName);
        localStorage.setItem('trackUrl', nextTrack.url);
        localStorage.setItem('title', nextTrack.title);
        localStorage.setItem('artist', nextTrack.artist);
        localStorage.setItem('imgName', nextTrack.imgName);
        localStorage.setItem('currentTrackIndex', nextTrackIndex);
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setPosition({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        });
        document.body.style.userSelect = 'none'; // Отключить выделение текста
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - position.x,
                y: e.clientY - position.y,
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        // localStorage.setItem('playerPositionX', position.x);
        // localStorage.setItem('playerPositionY', position.y);
        document.body.style.userSelect = 'auto'; // Включить выделение текста обратно
    };

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
        localStorage.setItem('playerCollapsed', !isCollapsed);
    };

    return (
        <>
            {user && trackUrl ? (
                <>
                    <audio
                        ref={audioRef}
                        src={trackUrl}
                        onLoadedMetadata={handleLoadedMetadata}
                        onTimeUpdate={handleTimeUpdate}
                    />
                    {
                        isCollapsed ? (
                            <div className={"miniLargePlayer"}
                                 style={{
                                     left: `${position.x}px`,
                                     top: `${position.y}px`,
                                     position: 'absolute',
                                     marginBottom: '20px',
                                     transform: 'translateX(-50%)'
                                 }}
                            >
                                <div>
                                    <img src={"https://api.muzilok.ru/uploads/albums_photos/" + imgName}
                                         width="100%" className={"rounded"} onMouseDown={handleMouseDown}/>
                                    <div className="titlesTextPlayer mt-2 text-center">
                                        <b>{title}</b>
                                        <p>{artist}</p>
                                    </div>

                                    <div className="w-100 d-flex align-items-center justify-content-center">
                                        <span onClick={() => togglePlay(title, artist, imgName)}
                                              className="mr-2 c-pointer">
                                        {isPlaying ?
                                            (<svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                  height="25"
                                                  fill="currentColor"
                                                  className="bi bi-pause" viewBox="0 0 16 16">
                                                <path
                                                    d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5"/>
                                            </svg>)
                                            :
                                            (<svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                  height="25"
                                                  fill="currentColor"
                                                  className="bi bi-play" viewBox="0 0 16 16">
                                                <path
                                                    d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z"/>
                                            </svg>)
                                        }
                                    </span>
                                    </div>

                                    <div className="w-100 d-flex align-items-center justify-content-between pl-2 pr-2">
                                        <span onMouseDown={handleMouseDown} className="c-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                 fill="currentColor" className="bi bi-arrows-move" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                      d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
                                            </svg>
                                        </span>

                                        <span onClick={toggleCollapse} className="c-pointer">
                                            {isCollapsed ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                     fill="currentColor" className="bi bi-arrows-angle-expand"
                                                     viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd"
                                                          d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707"/>
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                     fill="currentColor" className="bi bi-arrows-angle-contract"
                                                     viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd"
                                                          d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707M15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707"/>
                                                </svg>
                                            )}
                                        </span>
                                    </div>

                                </div>

                            </div>
                        ) : (
                            <>
                                <p onClick={handleStop}
                                   className={"hidePlayerText d-flex align-items-center c-pointer"}>Скрыть плеер
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         width="25" height="25"
                                         fill="#e8804c"
                                         className="bi bi-caret-down"
                                         style={{marginLeft: 5}}
                                         viewBox="0 0 16 16">
                                        <path
                                            d="M3.204 5h9.592L8 10.481zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659"/>
                                    </svg>
                                </p>
                                <div
                                    className={`largePlayer ${isVisible ? 'visible' : ''}`}
                                >
                                    <div className="blockPlayer row">
                                        <div className="col-12 col-lg-4">
                                            <div className="titlesPlayer">
                                                <img src={"https://api.muzilok.ru/uploads/albums_photos/" + imgName}
                                                     width="30px" onMouseDown={handleMouseDown}/>
                                                <div className="titlesTextPlayer">
                                                    <b>{title}</b>
                                                    <p>{artist}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {!isCollapsed && (
                                            <div className="col-12 col-lg-8">
                                                <div className="controlsLargePlayer d-flex align-items-center">
                                                    <span onClick={handlePreviousTrack} className="mr-2 c-pointer">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                             fill="currentColor"
                                                             className="bi bi-skip-backward" viewBox="0 0 16 16">
                                                            <path
                                                                d="M.5 3.5A.5.5 0 0 1 1 4v3.248l6.267-3.636c.52-.302 1.233.043 1.233.696v2.94l6.267-3.636c.52-.302 1.233.043 1.233.696v7.384c0 .653-.713.998-1.233.696L8.5 8.752v2.94c0 .653-.713.998-1.233.696L1 8.752V12a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5m7 1.133L1.696 8 7.5 11.367zm7.5 0L9.196 8 15 11.367z"/>
                                                        </svg>
                                                    </span>
                                                    <span onClick={() => togglePlay(title, artist, imgName)}
                                                          className="mr-2 c-pointer">
                                                        {isPlaying ?
                                                            (<svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                                  height="25"
                                                                  fill="currentColor"
                                                                  className="bi bi-pause" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5"/>
                                                            </svg>)
                                                            :
                                                            (<svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                                  height="25"
                                                                  fill="currentColor"
                                                                  className="bi bi-play" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z"/>
                                                            </svg>)
                                                        }
                                                    </span>
                                                    <span onClick={handleNextTrack} className="mr-2 c-pointer">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                             fill="currentColor"
                                                             className="bi bi-skip-forward" viewBox="0 0 16 16">
                                                            <path
                                                                d="M15.5 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V8.752l-6.267 3.636c-.52.302-1.233-.043-1.233-.696v-2.94l-6.267 3.636C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696L7.5 7.248v-2.94c0-.653.713-.998 1.233-.696L15 7.248V4a.5.5 0 0 1 .5-.5M1 4.633v6.734L6.804 8zm7.5 0v6.734L14.304 8z"/>
                                                        </svg>
                                                    </span>
                                                    <input
                                                        type="range"
                                                        value={currentTime}
                                                        step="1"
                                                        min="0"
                                                        max={duration}
                                                        className="custom-range mx-2 flex-grow-1"
                                                        onChange={(e) => audioRef.current.currentTime = e.target.value}
                                                    />
                                                </div>
                                                <div className={"d-flex justify-content-between"}
                                                     style={{marginLeft: 115, width: "calc(100% - 185px)"}}>
                                                    <b>{formatTime(currentTime)}</b>
                                                    <b>{formatTime(duration)}</b>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/*<span onClick={handleStop} className="c-pointer">*/}
                                    {/*    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"*/}
                                    {/*         fill="currentColor"*/}
                                    {/*         className="bi bi-x-circle-fill" viewBox="0 0 16 16">*/}
                                    {/*      <path*/}
                                    {/*          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>*/}
                                    {/*    </svg>*/}
                                    {/*</span>*/}
                                    {/*<span onMouseDown={handleMouseDown} className="c-pointer">*/}
                                    {/*    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"*/}
                                    {/*         fill="currentColor"*/}
                                    {/*         className="bi bi-arrows-move" viewBox="0 0 16 16">*/}
                                    {/*      <path fill-rule="evenodd"*/}
                                    {/*            d="M8 1.5a.5.5 0 0 1 .5.5v5.5h5.5a.5.5 0 0 1 0 1H8.5V14a.5.5 0 0 1-1 0V8.5H2a.5.5 0 0 1 0-1h5.5V2a.5.5 0 0 1 .5-.5z"/>*/}
                                    {/*    </svg>*/}
                                    {/*</span>*/}
                                    <span onClick={toggleCollapse} className="c-pointer">
                                        {isCollapsed ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-arrows-angle-expand"
                                                 viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                      d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707"/>
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-arrows-angle-contract"
                                                 viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                      d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707M15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707"/>
                                            </svg>
                                        )}
                                    </span>
                                </div>
                            </>
                        )
                    }
                </>
            ) : (<></>)}
        </>
    );
};

export default LargePlayer;