import React from 'react';
import { motion } from 'framer-motion';

const animationVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.2 } },
    exit: { opacity: 0, y: 20, transition: { duration: 0.2 } }
};

const AnimatedComponent = ({ children }) => {
    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={animationVariants}
        >
            {children}
        </motion.div>
    );
};

export default AnimatedComponent;