import React, { useState, useRef, useEffect, useCallback  } from 'react';

const MiniPlayer = ({ track }) => {
    const formatTime = (time) => {
        if (!time) return '00:00';

        const roundedTime = Math.round(time);
        const minutes = Math.floor(roundedTime / 60);
        const seconds = Math.round(roundedTime % 60);

        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const audioRef = useRef(new Audio(track.src));
    const { duration } = audioRef.current;

    const [isReady, setIsReady] = useState(false);
    const rafIdRef = useRef();

    const animateTime = () => {
        setCurrentTime(audioRef.current.currentTime);
        rafIdRef.current = requestAnimationFrame(animateTime);
    };

    const togglePlay = useCallback(async () => {
        setIsPlaying((prevIsPlaying) => {
            if (!prevIsPlaying) {
                audioRef.current.play();
                rafIdRef.current = requestAnimationFrame(animateTime);
            } else {
                audioRef.current.pause();
                cancelAnimationFrame(rafIdRef.current);
            }
            return !prevIsPlaying;
        });
    }, [track.src]);

    useEffect(() => {
        const audio = audioRef.current;
        if (!isReady && track.src) {
            audio.src = track.src;
            audio.onloadedmetadata = () => {
                setCurrentTime(audio.currentTime);
                setIsReady(true);
            };
        }

        return () => {
            audio.pause();
            cancelAnimationFrame(rafIdRef.current);
        };
    }, [track.src, isReady]);

    const handleSeekChange = (e) => {
        cancelAnimationFrame(rafIdRef.current);
        setCurrentTime(e.target.value);
    };

    const handleSeekMouseUp = () => {
        audioRef.current.currentTime = currentTime;
        rafIdRef.current = requestAnimationFrame(animateTime);
    };
    return (
        <div className="audio-player bg-dark text-light text-left rounded">
            <div className="track-info">
                <h3 className="text-truncate">{track.title}</h3>
                <p>Размер файла: {track.filesize}</p>
            </div>
            <div className="controls d-flex align-items-center">
                <button onClick={togglePlay} className="btn mainButton btn-sm mr-2">
                    {isPlaying ? <i className="bi bi-pause-fill"></i> : <i className="bi bi-play-fill"></i>}
                </button>
                <input
                    type="range"
                    className="custom-range mx-2"
                    min="0"
                    max={duration || 0}
                    value={currentTime}
                    onInput={handleSeekChange}
                    onMouseUp={handleSeekMouseUp}
                    onTouchEnd={handleSeekMouseUp}
                    style={{ flex: '1' }}
                />
                <div className="text-muted">
                    {formatTime(currentTime)} / {formatTime(duration)}
                </div>
            </div>
        </div>
    );
};

export default MiniPlayer;