import React, { useState, useContext, useEffect } from 'react';
import HeaderModal from '../components/Blocks/HeaderModal';
import { TrackContext } from '../API/TrackContext';
import MiniPlayer from '../blocks/MiniPlayer';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import ArtistSelect from './../components/Blocks/SelectArtist';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function splitArtist(inputString) {
    if (inputString.includes(',')) {
        return inputString.split(',');
    } else {
        return [inputString];
    }
}

const EditTrackModal = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [duration, setDuration] = useState(0);
    const [fileSize, setFileSize] = useState('');
    const [trackBase64data, setBase64Data] = useState('track');
    const { editTrack, wait, progress, getMyTrack } = useContext(TrackContext);
    const [inputError, setInputError] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [track, setTrack] = useState([]);
    const trackID = props.trackID;

    const [formData, setFormData] = useState({
        titleTrack: '',
        versionTrack: '',
        artistTrack: [],
        secondArtistTrack: [],
        authorTrack: '',
        composerTrack: '',
        leksikaTrack: false,
        ISRCTrack: '',
        trackBase64data: '',
        album_id: '',
        tiktok_start: '',
    });

    const loadFileFromUrl = async (fileUrl, albumId) => {
        setLoading(true);

        try {
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error('Сетевая ошибка при попытке загрузить файл');
            }
            const blob = await response.blob(); // Получаем blob из ответа
            const reader = new FileReader();

            reader.readAsDataURL(blob); // Конвертируем blob в base64
            reader.onload = () => {
                setLoading(false);
                setBase64Data(reader.result);
                handleChangeTrackBase64data(reader.result, albumId);
                console.log('File loaded and converted to Base64');
            };

            reader.onerror = (error) => {
                setLoading(false);
                console.error('Произошла ошибка при чтении файла:', error);
            };
        } catch (error) {
            setLoading(false);
            console.error('Ошибка загрузки файла:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getMyTrack({ trackID });
                setTrack(response);
                if(response) {
                    var track = response;
                    const trackUrl = "https://api.muzilok.ru/uploads/tracks/" + track.src;
                    // setFile(trackUrl);
                    if(formData.trackBase64data === '') {
                        setFormData({
                            album_id: track?.album_id || '',
                            titleTrack: track?.name,
                            artistTrack: track?.artist ? track?.artist.split(", ") : [],
                            secondArtistTrack: track?.second_artist ? track?.second_artist.split(", ") : [],
                            authorTrack: track?.author,
                            composerTrack: track?.composer,
                            leksikaTrack: track?.vocabulary === 'yes' ? true : false,
                            ISRCTrack: track?.ISRC,
                            trackBase64data: track?.src,
                            trackID: trackID,
                            versionTrack: track?.version,
                            tiktok_start: track?.start_tiktok
                        });
                        loadFileFromUrl(trackUrl);
                        console.log(response);
                    }
                }
            } catch (error) {
                console.error("Error fetching album:", error);
            }
        };

        if(showModal) {
            fetchData();
        }
    }, [trackID, getMyTrack, showModal]);

    const selectMain = () => setSelectedTypeArtist('main');
    const selectFeat = () => setSelectedTypeArtist('feat');

    const [artistName, setArtistName] = useState('');
    const [selectedTypeArtist, setSelectedTypeArtist] = useState('main');
    const [showModalArtist, setShowModalArtist] = useState(false);

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSwitchChange = (event) => {
        setFormData({ ...formData, leksikaTrack: event.target.checked });
    };

    const handleChangeTrackBase64data = (newTrackBase64data, albumid) => {
        setFormData(prevState => ({
            ...prevState,
            trackBase64data: newTrackBase64data,
            album_id: albumid,
        }));
        console.log('change');
    };

    const submitForm = async (e) => {
        e.preventDefault();

        // if (!Object.values(formData).every(val => val.trim() !== '')) {
        //     setSuccessMsg(false);
        //     setErrMsg('Пожалуйста, заполните все поля!');
        //     return;
        // }

        const data = await editTrack(formData);
        console.log(data);
        if (data.success === 1) {
            handleClose();
        }
        else if (!data.success && data.message) {
            setSuccessMsg(false);
            setErrMsg(data.message);
            if(data.type === 'input') {
                setInputError(true);
            }
        }

    }

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setFile(file);
        setFileSize(formatBytes(file.size));
        setLoading(true);

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            setLoading(false);
            setBase64Data(reader.result);
            handleChangeTrackBase64data(reader.result, props.albumid);
        };

        reader.onerror = () => {
            setLoading(false);
            console.error('Произошла ошибка при чтении файла');
        };
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setShowModal(true);
    };
    const handleClose = () => {
        setShowModal(false);
        setFile(null);
        setLoading(false);
    };

    useEffect(() => {
        if(props.artist && props.secondArtist) {
            if(props.artist.includes(', ')) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    artistTrack: props.artist.split(', ')
                }));
            } else {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    artistTrack: [props.artist]
                }));
            }

            if(props.secondArtist.includes(', ')) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    secondArtistTrack: props.secondArtist.split(', ')
                }));
            } else {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    secondArtistTrack: [props.secondArtist]
                }));
            }
        }
    }, [props.artist, props.secondArtist])

    const addArtist = () => {
        if (artistName.trim() === '') {
            alert('Введите имя артиста.');
            return;
        }

        if (selectedTypeArtist === 'main') {
            setFormData(prevFormData => ({
                ...prevFormData,
                artistTrack: [...prevFormData.artistTrack, artistName]
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                secondArtistTrack: [...prevFormData.secondArtistTrack, artistName]
            }));
        }

        setArtistName('');
        closeModal();
    };

    const removeArtist = (indexToRemove) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            artistTrack: prevFormData.artistTrack.filter((_, index) => index !== indexToRemove)
        }));
    };

    const removeArtistSecond = (indexToRemove) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            secondArtistTrack: prevFormData.secondArtistTrack.filter((_, index) => index !== indexToRemove)
        }));
    };

    const openModal = () => {
        setShowModalArtist(true);
    };

    const closeModal = () => {
        setShowModalArtist(false);
        selectMain();
    };

    return (
        <>
            <Tooltip title="Редактировать" onClick={handleClickOpen}>
                <IconButton>
                    <SvgIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor"
                             className="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path
                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fillRule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                        </svg>
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <Dialog
                fullScreen
                open={showModal}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <br />
                <HeaderModal title={`Редактирование трека "${formData.titleTrack}"`} closeModal={handleClose} />
                <div className='row text-left p-4'>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body p-4 mt-4 mb-4">
                                <label htmlFor="upload_track_wav" className='btn mainButton'>Загрузить WAV</label>
                                <input type="file" accept="audio/wav" className='d-none' id='upload_track_wav'
                                       onChange={handleFileInputChange}/>
                                {loading && <div>
                                    <CircularProgress color="success"/>
                                </div>}
                                {file && !loading && (
                                    <MiniPlayer track={{
                                        title: file.name,
                                        filesize: fileSize,
                                        src: URL.createObjectURL(file),
                                        duration: duration
                                    }}/>
                                )}
                                <br/> <b className="text-danger mt-2">Требования к файлу WAV: 44.100 Гц, 16/24
                                бит</b><br/>
                            </div>
                        </div>
                        <form onSubmit={submitForm}>
                            {/*<label className='mt-2' htmlFor="titleTrack">Название</label>*/}
                            {/*<input onChange={onChangeInput} value={formData.titleTrack} type="text" id="titleTrack" name="titleTrack" className='form-control' placeholder='Название' />*/}

                            {/*<label className='mt-2' htmlFor="versionTrack">Версия</label>*/}
                            {/*<input onChange={onChangeInput} value={formData.versionTrack} type="text" id="versionTrack" name="versionTrack" className='form-control' placeholder='Версия' />*/}

                            <div className="card">
                                <div className="card-body rounded p-4">
                                    <div className="p-2">
                                        <h6>Основная информация</h6>
                                        <p className="text-muted">Заполните общую информацию по вашему релизу</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-4 col-md-6 mt-2 p-2">
                                            <label
                                                className={`${inputError && !formData.titleTrack ? 'text-danger' : 'text-muted'}`}
                                                htmlFor="name_track">Название трека <small
                                                className={`${inputError && !formData.titleTrack ? 'text-danger' : 'text-muted'}`}>*</small></label>
                                            <input onChange={onChangeInput} value={formData.titleTrack} type="text"
                                                   id="titleTrack" name="titleTrack"
                                                   className={`form-control ${inputError && !formData.titleTrack ? 'border-danger' : ''}`}
                                                   placeholder='Название'/>
                                        </div>
                                        <div className="col-12 col-lg-4 col-md-6 mt-2 p-2">
                                            <label className="text-muted" htmlFor="version_track">Версия
                                                трека</label>
                                            <input onChange={onChangeInput} value={formData.versionTrack}
                                                   type="text" id="versionTrack" name="versionTrack"
                                                   className='form-control' placeholder='Версия'/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body rounded p-4 mt-4">
                                    <div className="p-2">
                                        <h6>Персоны релиза</h6>
                                        <p className="text-muted">Укажите людей, участвовавших в треке</p>
                                        {inputError && formData.artistTrack.length < 1 &&
                                            <p className="text-danger">Необходимо указать как минимум 1 основного
                                                артиста</p>
                                        }
                                    </div>
                                    <div className="w-100 d-flex mb-4">
                                        {formData.artistTrack.map((artist, index) =>
                                            <div className="artist-block d-flex align-items-center" key={index}>
                                                <b className="mr-2">{artist}</b>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor" className="bi bi-trash c-pointer"
                                                     viewBox="0 0 16 16" onClick={() => removeArtist(index)}>
                                                    <path
                                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                                    <path
                                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-100 d-flex mb-4">
                                        {formData.secondArtistTrack.map((artist, index) =>
                                            <div className="artist-block d-flex align-items-center" key={index}>
                                                <b className="mr-2">{artist} (feat.)</b>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor" className="bi bi-trash c-pointer"
                                                     viewBox="0 0 16 16" onClick={() => removeArtistSecond(index)}>
                                                    <path
                                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                                    <path
                                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                    <label className="btn mainButton d-flex align-items-center" onClick={openModal}
                                           style={{width: "min-content"}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-plus-lg mr-2" viewBox="0 0 16 16">
                                            <path fillRule="evenodd"
                                                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                                        </svg>
                                        Добавить артиста
                                    </label>
                                    {showModalArtist && (
                                        <div className="modal modal-add-track" style={{display: "block"}}>
                                            <div className="modal-content">
                                                <HeaderModal title="Добавить артиста" closeModal={closeModal}/>
                                                <div className='row text-left p-4 justify-content-center'>
                                                    <div className="col-12 col-md-6">
                                                        <ArtistSelect closeModal={closeModal} formData={formData} setFormData={setFormData} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body rounded mt-4 p-4">
                                    <div className="p-2">
                                        <h6>Авторская информация</h6>
                                        <p className="text-muted">Заполните авторскую информацию о треке</p>
                                        <b className="text-danger">(В полях автор и композитор укажите настоящее
                                            ФИО)</b>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-4 col-md-6 mt-2 p-2">
                                            <label
                                                className={`${inputError && !formData.authorTrack ? 'text-danger mt-2' : 'text-muted mt-2'}`}
                                                htmlFor="authorTrack">Автор <small
                                                className={`${inputError && !formData.authorTrack ? 'text-danger' : 'text-muted'}`}>*</small></label>
                                            <input onChange={onChangeInput} value={formData.authorTrack} type="text"
                                                   id="authorTrack" name="authorTrack"
                                                   className={`form-control ${inputError && !formData.authorTrack ? 'border-danger' : ''}`}
                                                   placeholder='Автор'/>
                                        </div>
                                        <div className="col-12 col-lg-4 col-md-6 mt-2 p-2">
                                            <label
                                                className={`${inputError && !formData.composerTrack ? 'text-danger mt-2' : 'text-muted mt-2'}`}
                                                htmlFor="composerTrack">Композитор <small
                                                className={`${inputError && !formData.composerTrack ? 'text-danger' : 'text-muted'}`}>*</small></label>
                                            <input onChange={onChangeInput} value={formData.composerTrack}
                                                   type="text" id="composerTrack" name="composerTrack"
                                                   className={`form-control ${inputError && !formData.composerTrack ? 'border-danger' : ''}`}
                                                   placeholder='Композитор'/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-3">
                                <div className="card-body rounded mt-4 p-4">
                                    <div className="row">
                                        <TimeInput formData={formData} setFormData={setFormData}/>
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-3">
                                <div className="card-body rounded mt-4 p-4">
                                    <div className="row">
                                        <label className='mt-2' htmlFor="ISRCTrack">ISRC</label>
                                        <input onChange={onChangeInput} value={formData.ISRCTrack} type="text"
                                               id="ISRCTrack" name="ISRCTrack" className='form-control'
                                               placeholder='ISRC'/>
                                        <div className="mb-4 mt-4">
                                            <Switch defaultChecked={formData.leksikaTrack}
                                                    onChange={handleSwitchChange}/> Присутствие ненормативной
                                            лексики
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {errMsg &&
                                <Alert variant="outlined" severity="error">
                                    {errMsg}
                                </Alert>
                            }
                            {successMsg &&
                                <Alert variant="outlined" severity="success">
                                    {successMsg}
                                </Alert>
                            }

                            {wait ? (
                                <LinearProgressWithLabel value={progress}/>
                            ) : (
                                <button className='btn mainButton mt-3' disabled={wait}>Обновить трек</button>
                            )}
                        </form>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

function TimeInput({formData, setFormData}) {

    const [value, setValue] = useState(formData.tiktok_start);

    const handleChange = (event) => {
        let input = event.target.value;
        input = input.replace(/[^0-9:]/g, '');
        input = input.replace(/:*$/, '');

        input = input.slice(0, 5);
        if (input.length === 2 && event.nativeEvent.data !== ':') {
            input += ':';
        }

        input = input.length > 2 ? input.substring(0, 2) + ':' + input.substring(3, 5) : input;

        setValue(input);
        setFormData({
            ...formData,
            tiktok_start: input,
        })
    };

    useEffect(() => {
        setValue(formData.tiktok_start !== null ? formData.tiktok_start : '');
    }, [formData.tiktok_start]);

    return (
        <div className="custom-input-container">
            <h6>Время в тикток</h6>
            <p className="text-muted">Выберите время, с которого будет начинаться открывок в тикток.</p>
            <input
                type="text"
                value={value}
                onChange={handleChange}
                maxLength="5"
                className="inputTime"
                placeholder="01:00"
            />
        </div>
    );
}

export default EditTrackModal;
