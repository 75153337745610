import { useContext, useState, useEffect } from 'react'
import {UserContext} from '../../API/UserContext';
import { Link } from 'react-router-dom';
import ControlArtists from './../../components/Blocks/ControlArtists';

import diamondGif from './../../assets/gif/diamond.gif';
import rockOnGif from './../../assets/gif/rock-on.gif';

const Settings = () => {

    const { editPersonalInfoUser, changeLinksUser } = useContext(UserContext);
    const { user } = useContext(UserContext);

    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);

    const [errMsgLinks, setErrMsgLinks] = useState(false);
    const [successMsgLinks, setSuccessMsgLinks] = useState(false);

    const [errMsgPassword, setErrMsgPassword] = useState(false);
    const [successMsgPassword, setSuccessMsgPassword] = useState(false);

    const [formData, setFormData] = useState({
        name: user?.name,
        lastname: user?.lastname,
        surname: user?.surname,
    });

    const [formDataLinks, setFormDataLinks] = useState({
        vkLink: user?.vkLink,
        spotify: user?.spotify
    });

    const onChangeInputLinks = (e) => {
        setFormDataLinks({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    useEffect(() => {
        document.title = "Настройки аккаунта";
    }, []);

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if(!Object.values(formData).every(val => val.trim() !== '')){
            setSuccessMsg(false);
            setErrMsg('Пожалуйста, заполните все поля!');
            return;
        }

        const data = await editPersonalInfoUser(formData);
        if(data.status == 'success'){
            console.log(data);
            setSuccessMsg(data.message);
            setErrMsg(false);
        }
        else {
            setSuccessMsg(false);
            setErrMsg(data.message);
        }

    }

    const submitFormLinks = async (e) => {
        e.preventDefault();

        if(!Object.values(formData).every(val => val.trim() !== '')){
            setSuccessMsgLinks(false);
            setErrMsg('Пожалуйста, заполните все поля!');
            return;
        }

        const data = await changeLinksUser(formDataLinks);
        if(data.status == 'success'){
            setSuccessMsgLinks(data.message);
            setErrMsgLinks(false);
        }
        else {
            setSuccessMsgLinks(false);
            setErrMsgLinks(data.message);
        }

    }

    const submitFormPassword = async (e) => {
        e.preventDefault();
        setErrMsgPassword('Обновление пароля временно недоступно');
        // if(!Object.values(formData).every(val => val.trim() !== '')){
        //     setSuccessMsg(false);
        //     setErrMsg('Please Fill in all Required Fields!');
        //     return;
        // }

        // const data = await editPersonalInfoUser(formData);
        // if(data.status == 'success'){
        //     setSuccessMsg(data.message);
        //     setErrMsg(false);
        // }
        // else {
        //     setSuccessMsg(false);
        //     setErrMsg(data.message);
        // }
        
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('ru-RU', options);
    };

    return (
        <div className="w-100 m-auto">
            <h3>Настройки профиля</h3>
            {/*<Link to="/settings/subscribe">Настройки подписки</Link>*/}

            <div className="row">
                <div className="col-12 col-lg-6 col-xl-4 p-1">
                    <div className="card">
                        <div className="card-body rounded">
                            <h5>Персональная информация</h5>
                            <form action="" onSubmit={submitForm} className='mb-4' autoComplete="off">
                                <div className="row w-100 mt-2">
                                    <div className="col-12 p-1 mt-2">
                                        <input id="lastname" type="text" onChange={onChangeInput} name="lastname"
                                               className="form-control" placeholder="Фамилия"
                                               value={formData.lastname}/>
                                    </div>
                                    <div className="col-12 p-1 mt-2">
                                        <input id="name" type="text" onChange={onChangeInput} name="name"
                                               className="form-control"
                                               placeholder="Имя" value={formData.name}/>
                                    </div>
                                    <div className="col-12 p-1 mt-2">
                                        <input id="surname" type="text" onChange={onChangeInput} name="surname"
                                               className="form-control"
                                               placeholder="Отчество" value={formData.surname}/>
                                    </div>
                                </div>
                                {successMsg && <div className="alert alert-success w-50 mt-2 ml-1">{successMsg}</div>}
                                {errMsg && <div className="err-msg alert alert-danger w-50 mt-2 ml-1">{errMsg}</div>}
                                <button type="submit" className="btn mainButton btn-sm ml-1 mt-2">Сохранить личную
                                    информацию
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4 p-1">
                    <div className="card">
                        <div className="card-body rounded">
                            <h5 className="mt-2">Подписка</h5>
                            <div className="status-alert-album d-flex align-items-center custom-color-text w-100 mt-3">
                                {
                                    user.subscribe_type == 1 ? (
                                        <div className="d-flex align-items-center">
                                            <img src={rockOnGif} className={"mr-2"} width={"40px"} height={"40px"} />
                                            Стандарт
                                        </div>
                                    ) : user.subscribe_type == 2 ? (
                                        <div className="d-flex align-items-center">
                                            <img src={diamondGif} className={"mr-2"} width={"40px"} height={"40px"}/>
                                            Премиум
                                        </div>
                                    ) : user.subscribe_type == 3 ? (
                                        <div className="d-flex align-items-center">
                                            <img src={diamondGif} className={"mr-2"} width={"40px"} height={"40px"}/>
                                            Супер
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor"
                                                 className="bi bi-x-circle-fill mr-2" viewBox="0 0 16 16">
                                                <path
                                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
                                            </svg>
                                            Нет подписки
                                        </div>
                                    )
                                }
                            </div>
                            {
                                user.subscribe_type > 1 && (
                                    <p className={"mt-2"}>Действует до {formatDate(user.subscribe_date_end.substring(0, 10))}</p>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4 p-1">
                    <div className="card">
                        <div className="card-body rounded">
                            <h5>Обновление пароля</h5>
                            <form action="" onSubmit={submitFormPassword} className='mb-4' autoComplete="off">
                                <div className="row w-100 mt-2">
                                    <div className="col-12 p-1 mt-2">
                                        <input type="text" onChange={onChangeInput} name="current_password"
                                               className="form-control"
                                               placeholder="Текущий пароль"/>
                                    </div>
                                    <div className="col-12 p-1 mt-2">
                                        <input type="text" onChange={onChangeInput} name="new_password"
                                               className="form-control"
                                               placeholder="Новый пароль"/>
                                    </div>
                                    <div className="col-12 p-1 mt-2">
                                        <input type="text" onChange={onChangeInput} name="new_password_repeat"
                                               className="form-control"
                                               placeholder="Повторите новый пароль"/>
                                    </div>
                                </div>
                                {successMsgPassword &&
                                    <div className="alert alert-success w-50 mt-2 ml-1">{successMsgPassword}</div>}
                                {errMsgPassword &&
                                    <div className="err-msg alert alert-danger w-50 mt-2 ml-1">{errMsgPassword}</div>}
                                <button type="submit" className="btn btn-sm mainButton ml-1 mt-2">Обновить пароль
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                {/*<div className="col-12 col-lg-6 col-xl-4 p-1">*/}
                {/*    <div className="card">*/}
                {/*        <div className="card-body rounded">*/}
                {/*            <h5 className="mt-2">Ссылки</h5>*/}
                {/*            <form action="" onSubmit={submitFormLinks} className='mb-4' autoComplete="off">*/}
                {/*                <div className="row w-100 mt-2">*/}
                {/*                    <div className="col-12 p-1 mt-2">*/}
                {/*                        <label htmlFor="lastname">Карточка артиста в VK:</label>*/}
                {/*                        <div className="input-group">*/}
                {/*                            <div className="input-group-prepend">*/}
                {/*            <span className="input-group-text" id="basic-addon1"*/}
                {/*                  style={{background: "#1c1c1c", border: "1px solid #6c757d", borderRight: 0}}>*/}
                {/*                <img*/}
                {/*                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/VK_Compact_Logo_%282021-present%29.svg/1024px-VK_Compact_Logo_%282021-present%29.svg.png"*/}
                {/*                    alt="Spotify"*/}
                {/*                    style={{height: '23px'}}/>*/}
                {/*            </span>*/}
                {/*                            </div>*/}
                {/*                            <input*/}
                {/*                                id="vkLink"*/}
                {/*                                type="text"*/}
                {/*                                onChange={onChangeInputLinks}*/}
                {/*                                name="vkLink"*/}
                {/*                                className="form-control"*/}
                {/*                                placeholder="Ссылка на VK"*/}
                {/*                                value={formDataLinks.vkLink}*/}
                {/*                                aria-describedby="basic-addon1"*/}
                {/*                            />*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="col-12 p-1 mt-2">*/}
                {/*                        <label htmlFor="spotify">Карточка артиста в Spotify:</label>*/}
                {/*                        <div className="input-group">*/}
                {/*                            <div className="input-group-prepend">*/}
                {/*            <span className="input-group-text" id="basic-addon1"*/}
                {/*                  style={{background: "#1c1c1c", border: "1px solid #6c757d", borderRight: 0}}>*/}
                {/*                <img src="https://muzilok.ru/images/logos/spotify.png" alt="Spotify"*/}
                {/*                     style={{height: '23px'}}/>*/}
                {/*            </span>*/}
                {/*                            </div>*/}
                {/*                            <input*/}
                {/*                                id="spotify"*/}
                {/*                                type="text"*/}
                {/*                                onChange={onChangeInputLinks}*/}
                {/*                                name="spotify"*/}
                {/*                                className="form-control"*/}
                {/*                                placeholder="Ссылка на Spotify"*/}
                {/*                                value={formDataLinks.spotify}*/}
                {/*                                aria-describedby="basic-addon1"*/}
                {/*                            />*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                {successMsgLinks &&*/}
                {/*                    <div className="alert alert-success w-50 mt-2 ml-1">{successMsgLinks}</div>}*/}
                {/*                {errMsgLinks &&*/}
                {/*                    <div className="err-msg alert alert-danger w-50 mt-2 ml-1">{errMsgLinks}</div>}*/}
                {/*                <button type="submit" className="btn mainButton btn-sm ml-1 mt-2">Обновить ссылки</button>*/}
                {/*            </form>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="mt-2 p-1">
                <ControlArtists/>
            </div>

        </div>
    );
}

export default Settings;