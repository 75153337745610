import { useContext, useState, useEffect } from 'react'
import {UserContext} from '../../API/UserContext';
import { Link } from 'react-router-dom';

const SubscribeSettings = () => {

    const { user } = useContext(UserContext);

    useEffect(() => {
        document.title = "Настройки подписки";
    }, []);

    return (
        <div className="w-100 m-auto">
            <h3>Настройки подписки</h3>
            <h5 className="mt-3">Тип подписки:</h5>
            <div className="status-alert-album d-flex align-items-center custom-color-text mt-3">{
                user.subscribe_type == 1 ? (
                    <div className="d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-person mr-2" viewBox="0 0 16 16">
                            <path
                                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                        </svg>
                        Стандарт
                    </div>
                ) : user.subscribe_type == 2 ? (
                    <div className="d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-star-fill mr-2" viewBox="0 0 16 16">
                            <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg>
                        Премиум
                    </div>
                ) : user.subscribe_type == 3 ? (
                    <div className="d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-heart-fill mr-2" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                  d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/>
                        </svg>
                        Супер
                    </div>
                ) : (
                    <div className="d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-x-circle-fill mr-2" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
                        </svg>
                        Нет подписки
                    </div>
                )
            }
            </div>
            <div className="card mt-3">
                <div className="card-header pl-0">
                    <h5>Разовые карточки:</h5>
                </div>
                {/*<ul className="list-group bg-dark list-group-flush">*/}
                {/*    <li className="list-group-item bg-dark pl-0">Single: {user.count_single} шт.</li>*/}
                {/*    <li className="list-group-item bg-dark pl-0">EP: {user.count_ep} шт.</li>*/}
                {/*    <li className="list-group-item bg-dark pl-0">Album: {user.count_album} шт.</li>*/}
                {/*</ul>*/}
                <div className="tab-pane fade active show" id="pills-yearly" role="tabpanel"
                     aria-labelledby="pills-yearly-tab">
                    <div className="row">
                        <div className="col-lg-4 p-2">
                            <div className="card plan-card mt-4 rounded text-center border-0 shadow overflow-hidden">
                                <div className="card-body px-4 py-5">
                                    <div className="icon-mono avatar-md bg-soft-primary rounded mx-auto mb-5 p-3">
                                        <img src="https://muzilok.ru/images/pricing/1.png" alt=""
                                             className="img-fluid d-block mx-auto"/>
                                    </div>
                                    <h4 className="text-uppercase mb-4 pb-1">Single</h4>
                                    <h5>{user.count_single} шт.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-2">
                            <div className="card plan-card mt-4 rounded text-center border-0 shadow overflow-hidden">
                                <div className="card-body px-4 py-5">
                                    <div className="icon-mono avatar-md bg-soft-primary rounded mx-auto mb-5 p-3">
                                        <img src="https://muzilok.ru/images/pricing/2.png" alt="" className="img-fluid d-block mx-auto"/>
                                    </div>
                                    <h4 className="text-uppercase mb-4 pb-1">EP</h4>
                                    <h5>{user.count_ep} шт.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-2">
                            <div className="card plan-card mt-4 rounded text-center border-0 shadow overflow-hidden">
                                <div className="card-body px-4 py-5">
                                    <div className="icon-mono avatar-md bg-soft-primary rounded mx-auto mb-5 p-3">
                                        <img src="https://muzilok.ru/images/pricing/3.png" alt=""
                                             className="img-fluid d-block mx-auto"/>
                                    </div>
                                    <h4 className="text-uppercase mb-4 pb-1">Album</h4>
                                    <h5>{user.count_album} шт.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Link className="mt-3" to="/privacy/subscribe">Пользовательское соглашение о подписке и разовых
                карточках</Link>
        </div>
    );
};

export default SubscribeSettings;